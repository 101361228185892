import PropTypes from 'prop-types';
import clsx from 'clsx';

import Title from '../../shared/components/Title/Title';
import Tooltip from '../../shared/components/Tooltip/Tooltip';
import Text from '../../shared/components/Text/Text';
import Button from '../../shared/components/Button/Button';
import CircularProgress from '../../shared/components/Progress/CircularProgress';

const Card = ({
	title,
	markdown,
	type,
	action,
	onCreate,
	onEdit,
	active,
	loading,
}) => {
	return (
		<div
			className={clsx(
				'relative bg-white rounded-lg border py-8 px-10 w-72 text-center',
				active ? 'border-ca-green' : 'border-ca-silver'
			)}
		>
			<Title type="section" className={clsx(active && 'text-ca-green')}>
				{title}
			</Title>
			<div className="relative mt-6">
				<div className="flex flex-col divide-y divide-ca-silver">
					<Text
						type={type}
						className={clsx(
							'pb-3',
							(action === 'create' || loading) && 'filter blur-sm'
						)}
					>
						<Tooltip placement="left" content="Average markdown">
							<span>{markdown}</span>
						</Tooltip>
					</Text>
				</div>
				{loading && (
					<div className="absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2">
						<CircularProgress size="small" />
					</div>
				)}
			</div>
			{!loading && action === 'create' && (
				<Button
					onClick={onCreate}
					className="absolute left-1/2 top-1/2 mt-6 transform -translate-y-1/2 -translate-x-1/2"
				>
					Create
				</Button>
			)}
			{!loading && action === 'edit' && (
				<Button
					onClick={onEdit}
					variant="secondary"
					size="small"
					className="absolute left-1/2 bottom-0 transform translate-y-1/2 -translate-x-1/2"
				>
					Edit settings
				</Button>
			)}
		</div>
	);
};

Card.propTypes = {
	title: PropTypes.string.isRequired,
	markdown: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['primary', 'secondary']),
	action: PropTypes.oneOf(['create', 'edit']),
	onCreate: PropTypes.func,
	onEdit: PropTypes.func,
	active: PropTypes.bool,
	loading: PropTypes.bool,
};

Card.defaultProps = {
	type: 'primary',
	action: null,
	onCreate: () => {},
	onEdit: () => {},
	active: false,
	loading: false,
};

export default Card;
