import clsx from 'clsx';
import { FunctionComponent, useState } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { Link } from 'react-router-dom';
import { useReports } from '../../../domains/domains';
import {
	GET_INVENTORY_ALLOCATION_REPORT,
	GET_REPORT_WITH_LIVE_STATUS,
} from '../../../shared/api/inventory-allocation-reports';
import CircularProgress from '../../../shared/components/Progress/CircularProgress';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import {
	InventoryAllocationReport,
	InventoryAllocationReportStatus,
} from '../../../shared/models/inventoryAllocationReport';
import {
	GetReportResponseDTO,
	ReportStatusDTO,
} from '../../../shared/models/schema';
import DangerIcon from '../../../shared/components/Icons/Danger';

export interface DraftTabLink {
	inventoryAllocationId: InventoryAllocation['id'];
	id: InventoryAllocationReport['id'];
	to: string;
}

interface Props extends DraftTabLink {
	isActive: boolean;
}

const DraftTab: FunctionComponent<Props> = ({
	inventoryAllocationId,
	id,
	to,
	isActive,
}) => {
	const [refetchInterval, setRefetchInterval] = useState<number | undefined>(
		undefined
	);
	const options: Omit<
		UseQueryOptions<GetReportResponseDTO, unknown>,
		'queryKey'
	> = {
		refetchInterval,
		onSuccess: (data) => {
			if (data.status === ReportStatusDTO.InProgress) {
				setRefetchInterval(10000);
			} else {
				setRefetchInterval(undefined);
			}
		},
	};
	const { entity: draft, isLoading } = useReports(
		useQuery(
			['reports', id],
			() => GET_INVENTORY_ALLOCATION_REPORT(inventoryAllocationId, id),
			options
		)
	);

	useQuery(
		['engine-live-status', id],
		() => GET_REPORT_WITH_LIVE_STATUS(inventoryAllocationId, id),
		options
	);

	return (
		<Link key={id} to={to} className="flex">
			<span
				className={clsx(
					'border border-ca-silver border-b-0 bg-white py-2 pl-4 font-bold rounded-t-md whitespace-nowrap flex',
					draft?.status !== InventoryAllocationReportStatus.InProgress &&
						draft?.status !== InventoryAllocationReportStatus.Error &&
						'pr-8',
					isActive
						? 'bg-opacity-50 text-ca-black'
						: 'bg-opacity-30 text-ca-gray',
					draft?.status === InventoryAllocationReportStatus.Error &&
						'text-ca-red bg-red'
				)}
			>
				{isLoading ? 'Loading...' : draft?.title}
				{draft?.status === InventoryAllocationReportStatus.InProgress && (
					<CircularProgress size="text" className="ml-4 mr-2" />
				)}
				{draft?.status === InventoryAllocationReportStatus.Error && (
					<DangerIcon className="ml-4 h-4 mt-1 mr-2" />
				)}
			</span>
		</Link>
	);
};

export default DraftTab;
