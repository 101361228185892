import Note from '../Note';
import MarkerLink from '../MarkerLink';
import Heading from '../Heading';

const ScenarioContent = () => (
	<div className="space-y-4" id="scenario">
		<Heading
			element="h2"
			className="pt-2 text-ca-section font-bold text-ca-purple"
			target="scenario"
		>
			Scenario
		</Heading>
		<p>
			In <MarkerLink to="/scenario">the scenario module</MarkerLink>, you can
			run simulations and see the expected results for different markdown
			strategies or business rules.
		</p>
		<p>
			<MarkerLink to="/scenario">The scenario dashboard</MarkerLink> will
			compare sales, turnover and margin until the end of the season for 3
			scenarios:
		</p>
		<ul className="list-outside pl-4 list-disc space-y-4">
			<li>
				<span>Current markdowns</span>
				<p className="flex">
					<span>&#8594;&nbsp;</span>
					<span>
						The expected results if the current markdowns are not changed.
					</span>
				</p>
			</li>
			<li>
				<span>Active strategy</span>
				<p className="flex">
					<span>&#8594;&nbsp;</span>
					<span>
						The expected results if all markdowns are changed to the recommended
						markdowns.
					</span>
				</p>
				<p className="mt-2">
					The active strategy is the one that you have set up in the main{' '}
					<MarkerLink to="#strategy">strategy module</MarkerLink> and that has
					been used to generate the recommendations in the{' '}
					<MarkerLink to="#opportunities">opportunities module</MarkerLink>.
				</p>
			</li>
			<li>
				<span>Test strategy</span>
				<p className="flex">
					<span>&#8594;&nbsp;</span>
					<span>
						The expected results for if you were to use a different strategy.
					</span>
				</p>
				<p className="mt-2">
					The test strategy is an alternative version of the markdown strategy,
					where you can simulate the effect of using a different optimization
					objective or where you can try out different business rules.
				</p>
			</li>
		</ul>
		<p>
			<MarkerLink to="/scenario">The scenario dashboard</MarkerLink> shows the
			relative sales, turnover and margin uplift for the active and test
			strategy compared to the current markdowns. Note that these uplifts are
			estimates based on the predicted sales for every product until the end of
			the season at different markdowns.
		</p>
		<Note title="Example">
			<div className="space-y-4">
				<p className="italic">Imagine the following scenarios:</p>
				<div className="border border-ca-black rounded-lg">
					<table className="w-full table-auto border-collapse text-left">
						<thead>
							<tr>
								<th className="font-bold p-2 border-r border-b border-ca-black">
									Scenario
								</th>
								<th className="font-bold p-2 border-x border-b border-ca-black">
									Avg. Markdown
								</th>
								<th className="font-bold p-2 border-x border-b border-ca-black">
									Sales uplift
								</th>
								<th className="font-bold p-2 border-x border-b border-ca-black">
									Turnover uplift
								</th>
								<th className="font-bold p-2 border-l border-b border-ca-black">
									Margin uplift
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-ca-black">
							<tr>
								<td className="p-2 border-r border-ca-black">
									Current markdown
								</td>
								<td className="p-2 border border-ca-black">20%</td>
								<td className="p-2 border border-ca-black">-</td>
								<td className="p-2 border border-ca-black">-</td>
								<td className="p-2 border-l border-ca-black">-</td>
							</tr>
							<tr>
								<td className="p-2 border-r border-ca-black">
									Active strategy
								</td>
								<td className="p-2 border border-ca-black bg-ca-purple bg-opacity-20">
									30%
								</td>
								<td className="p-2 border border-ca-black bg-ca-purple bg-opacity-20">
									20%
								</td>
								<td className="p-2 border border-ca-black bg-ca-purple bg-opacity-20">
									10%
								</td>
								<td className="p-2 border-l border-ca-black">2%</td>
							</tr>
							<tr>
								<td className="p-2 border-r border-ca-black">Test strategy</td>
								<td className="p-2 border border-b-0 border-ca-black">25%</td>
								<td className="p-2 border border-b-0 border-ca-black">10%</td>
								<td className="p-2 border border-b-0 border-ca-black">8%</td>
								<td className="p-2 border-l border-b-0 border-ca-black bg-ca-purple bg-opacity-20">
									5%
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>
					We can see that the active strategy is more aggressive. By increasing
					the markdowns with 10%, we can expect a bigger uplift in sales. We
					expect to sell 20% more units under the active strategy compared to
					the current markdown scenario. However, under the test strategy, we
					can generate a bigger uplift in margin.
				</p>
			</div>
		</Note>
		<div className="space-y-4" id="scenario-test-strategy">
			<Heading
				element="h3"
				className="pt-2 text-lg text-ca-purple"
				target="scenario-test-strategy"
			>
				Test strategy
			</Heading>
			<div
				className="space-y-4"
				id="scenario-test-strategy-setting-up-a-test-strategy"
			>
				<Heading
					element="h4"
					className="pt-2 text-sm font-bold text-ca-black"
					target="scenario-test-strategy-setting-up-a-test-strategy"
				>
					Setting up a test strategy
				</Heading>
				<p>
					<MarkerLink to="/scenario/strategy/test">
						The setup of a test strategy
					</MarkerLink>{' '}
					is similar to{' '}
					<MarkerLink to="/strategy/components">
						the strategy overview
					</MarkerLink>{' '}
					tab of the active markdown strategy. You can change the settings, such
					as the optimization objective, for one or more of the product
					categories. You can also enable or disable different business rules.
					If you want to add a new business rule, you should add it in the
					strategy module first, and enable it in your test strategy afterwards.
				</p>
			</div>
		</div>
	</div>
);

export default ScenarioContent;
