import { GET_CUMULIO_OPPORTUNITIES_OVERVIEW_SSO } from '../../shared/api/reports';
import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';

const OpportunitiesSummaryView = () => {
	return (
		<FullCumulioDashboard
			queryKey="cumulio-opportunities-overview"
			ssoQuery={GET_CUMULIO_OPPORTUNITIES_OVERVIEW_SSO}
		/>
	);
};

export default OpportunitiesSummaryView;
