import { Route, Redirect } from 'react-router-dom';

import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import Page from '../../shared/components/Page/Page';
import StrategyHeader from '../components/StrategyHeader';

import StrategyComponentsOverview from './Components/Overview';
import StrategyComponentsEdit from './Components/Edit';

import ProductCategoriesOverview from './ProductCategories/Overview';
import ProductCategoriesCreateEdit from './ProductCategories/CreateEdit';

import BusinessRulesOverview from './BusinessRules/Overview';
import BusinessRulesCreateEdit from './BusinessRules/CreateEdit';

const StrategyView = () => (
	<Page>
		<StrategyHeader />
		<RouterSwitch>
			<Route exact path="/strategy">
				<Redirect to="/strategy/components" />
			</Route>
			<Route
				exact
				path="/strategy/components"
				component={StrategyComponentsOverview}
			/>
			<Route
				exact
				path="/strategy/:strategyId/components/:componentId/edit"
				component={StrategyComponentsEdit}
			/>
			<Route
				exact
				path="/strategy/categories"
				component={ProductCategoriesOverview}
			/>
			<Route
				exact
				path="/strategy/categories/create"
				component={ProductCategoriesCreateEdit}
			/>
			<Route
				exact
				path="/strategy/categories/:categoryId/edit"
				component={ProductCategoriesCreateEdit}
			/>
			<Route
				exact
				path="/strategy/business-rules"
				component={BusinessRulesOverview}
			/>
			<Route
				exact
				path="/strategy/business-rules/create"
				component={BusinessRulesCreateEdit}
			/>
			<Route
				exact
				path="/strategy/business-rules/:businessRuleId/edit"
				component={BusinessRulesCreateEdit}
			/>
		</RouterSwitch>
	</Page>
);

export default StrategyView;
