import MarkerLink from '../MarkerLink';
import Heading from '../Heading';

const OverviewContent = () => (
	<div className="space-y-4" id="overview">
		<Heading
			element="h2"
			className="pt-2 text-ca-section font-bold text-ca-purple"
			target="overview"
		>
			Overview
		</Heading>
		<p>
			The objective of the Crunch Platform is to enhance the way merchandisers
			and pricing managers handle markdowns for end-of-life products.
		</p>
		<p>The Crunch Platform consists of 3 main modules:</p>
		<ul className="list-outside pl-4 list-disc space-y-4">
			<li>
				<MarkerLink to="/strategy">Strategy</MarkerLink>
				<p className="flex">
					<span>&#8594;&nbsp;</span>
					<span>
						Prepare for the sales season by setting up a markdown strategy and
						business rules.
					</span>
				</p>
			</li>
			<li>
				<MarkerLink to="/opportunities">Opportunities</MarkerLink>
				<p className="flex">
					<span>&#8594;&nbsp;</span>
					<span>
						Take action by validating, approving or overriding the recommended
						markdowns.
					</span>
				</p>
			</li>
			<li>
				<MarkerLink to="/scenario">Scenario</MarkerLink>
				<p className="flex">
					<span>&#8594;&nbsp;</span>
					<span>
						Understand what is going to happen by simulating the expected
						results for different markdown strategies or business rules.
					</span>
				</p>
			</li>
		</ul>
	</div>
);

export default OverviewContent;
