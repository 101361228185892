import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import useChannelStore from '../data/channels';

type UseChannelQueryResult<TError = unknown, TData = unknown> = UseQueryResult<
	TData,
	TError
> & {
	channel: string;
	queryKey: QueryKey;
};

// wrapper for useQuery that ensures caching on channel level
const useChannelQuery = <
	TQueryFnData = unknown,
	TError = unknown,
	TData = unknown
>(
	queryKey: QueryKey,
	...params: any
): UseChannelQueryResult<TError, TData> => {
	const activeChannel = useChannelStore((s) => s.activeChannel);

	let key = queryKey;
	if (!Array.isArray(queryKey)) {
		key = [queryKey];
	}

	const queryKeyWithActiveChannel: QueryKey = [activeChannel, ...key];

	return {
		...useQuery<TQueryFnData, TError, TData>(
			queryKeyWithActiveChannel,
			...params
		),
		channel: activeChannel,
		queryKey: queryKeyWithActiveChannel,
	};
};

export default useChannelQuery;
