import { useHistory } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const DocNavLink = ({
	to,
	children,
	className,
	activeClassName,
	inactiveClassName,
	active,
	...props
}) => {
	const history = useHistory();

	return (
		<ScrollLink
			className={clsx(className, active ? activeClassName : inactiveClassName)}
			to={to}
			onClick={() => history.push(`#${to}`)}
			{...props}
		>
			{children}
		</ScrollLink>
	);
};

DocNavLink.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	activeClassName: PropTypes.string,
	inactiveClassName: PropTypes.string,
	active: PropTypes.bool,
};

DocNavLink.defaultProps = {
	className: '',
	activeClassName: '',
	inactiveClassName: '',
	active: false,
};

export default DocNavLink;
