import Note from '../Note';
import MarkerLink from '../MarkerLink';
import Heading from '../Heading';

const OpportunitiesContent = () => (
	<div className="space-y-4" id="opportunities">
		<Heading
			element="h2"
			className="pt-2 text-ca-section font-bold text-ca-purple"
			target="opportunities"
		>
			Opportunities
		</Heading>
		<p>
			<MarkerLink to="/opportunities">The opportunities module</MarkerLink> is
			where markdown recommendations can be reviewed.
		</p>
		<p>It consist of several pages:</p>
		<ul className="list-outside pl-4 list-disc">
			<li>
				A <MarkerLink to="/opportunities/summary">summary dashboard</MarkerLink>{' '}
				of the recommended markdowns. This dashboard gives an overview of the
				recommended new markdowns. It can be used to see where and how much
				markdowns would be increased.
			</li>
			<li>
				A <MarkerLink to="/opportunities/details">detail page</MarkerLink> with
				markdown recommendations for every product.
			</li>
		</ul>
		<div className="space-y-4" id="opportunities-detail-page">
			<Heading
				element="h3"
				className="pt-2 text-lg text-ca-purple"
				target="opportunities-detail-page"
			>
				Detail page
			</Heading>
			<p>
				<MarkerLink to="/opportunities/details">The detail page</MarkerLink>{' '}
				shows <strong>the recommended markdown</strong> for every product along
				with some additional information, such as:
			</p>
			<ul className="list-outside pl-4 list-disc">
				<li>Current inventory level</li>
				<li>
					<span>Rotation</span>
					<p className="flex">
						<span>&#8594;&nbsp;</span>
						<span>
							The expected sales per week until the end of the season.
						</span>
					</p>
				</li>
				<li>
					<span>Lifetime</span>
					<p className="flex">
						<span>&#8594;&nbsp;</span>
						<span>The amount of weeks left until the end of the season.</span>
					</p>
				</li>
				<li>
					<span>Estimated overstock</span>
					<p className="flex">
						<span>&#8594;&nbsp;</span>
						<span>
							The expected rest stock given the current inventory and expected
							sales.
						</span>
					</p>
				</li>
			</ul>
			<p>For every product, you can see:</p>
			<ul className="list-outside pl-4 list-disc">
				<li>The current markdown</li>
				<li>
					<span>The optimal markdown</span>{' '}
					<p className="flex">
						<span>&#8594;&nbsp;</span>
						<span>
							This is the recommended markdown{' '}
							<strong>based on the optimization model</strong>.
						</span>
					</p>
					<p className="italic">
						Note that relevant business rules are already taken into account in
						this optimal markdown.
					</p>
				</li>
			</ul>
			<div
				className="space-y-4"
				id="opportunities-detail-page-attainable-increase"
			>
				<Heading
					element="h4"
					className="pt-2 text-sm font-bold text-ca-black"
					target="opportunities-detail-page-attainable-increase"
				>
					Attainable increase
				</Heading>
				<p>
					The overview table also shows the attainable increase. This is the{' '}
					<strong>additional value</strong> that can be achieved{' '}
					<strong>compared to the current markdown</strong>. This increase can
					be provided in terms of turnover, gross margin as well as the custom
					optimization objective you have selected in your markdown strategy.
				</p>
				<Note title="Example">
					<div className="space-y-4">
						<div>
							<p className="font-bold">Current markdown = 10%</p>
							<ul className="list-outside pl-4 list-disc">
								<li>Expected turnover: €1000</li>
								<li>Expected margin: €500</li>
							</ul>
						</div>
						<div>
							<p className="font-bold">Optimal markdown = 30%</p>
							<ul className="list-outside pl-4 list-disc">
								<li>Expected turnover: €1600</li>
								<li>Expected margin: €700</li>
							</ul>
						</div>
						<div>
							<p className="font-bold">Attainable increase</p>
							<p className="flex">
								<span>&#8594;&nbsp;</span>
								<span>Turnover: €600</span>
							</p>
							<p className="flex">
								<span>&#8594;&nbsp;</span>
								<span>Margin: €200</span>
							</p>
						</div>
					</div>
				</Note>
			</div>
			<div
				className="space-y-4"
				id="opportunities-detail-page-exporting-markdown-recommendations"
			>
				<Heading
					element="h4"
					className="pt-2 text-sm font-bold text-ca-black"
					target="opportunities-detail-page-exporting-markdown-recommendations"
				>
					Exporting markdown recommendations
				</Heading>
				<p className="mt-2">
					Once you have reviewed the markdown recommendations, you can export
					them to a .csv file. Select the products you want to export with the
					checkboxes on the left hand side of the table. Once you have selected
					one or more products, the export button will appear. Note that when
					exporting several thousands of products, the download can take up to
					1-2 minutes. Note that the format of the .csv file (e.g. which columns
					are being exported) can be customized. Please{' '}
					<MarkerLink href="mailto:pricing@crunchanalytics.be">
						contact Crunch
					</MarkerLink>{' '}
					to discuss your specific requirements.
				</p>
			</div>
		</div>
	</div>
);

export default OpportunitiesContent;
