import { useHistory } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Heading = ({ element, className, target, children }) => {
	const history = useHistory();
	const Element = element;

	return (
		<Element className={clsx('relative flex items-center group', className)}>
			<ScrollLink
				to={target}
				className="absolute -ml-8 flex items-center opacity-0 transition-opacity cursor-pointer group-hover:opacity-100"
				onClick={() => history.push(`#${target}`)}
				smooth
				duration={500}
			>
				<div className="w-6 h-6 flex items-center text-base justify-center rounded shadow-ca text-ca-gray hover:text-ca-black transition-colors">
					<svg width="12" height="12" fill="none" aria-hidden="true">
						<path
							d="M3.75 1v10M8.25 1v10M1 3.75h10M1 8.25h10"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
					</svg>
				</div>
			</ScrollLink>
			<span>{children}</span>
		</Element>
	);
};

Heading.propTypes = {
	element: PropTypes.oneOf(['h2', 'h3', 'h4']).isRequired,
	className: PropTypes.string,
	target: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

Heading.defaultProps = {
	className: '',
};

export default Heading;
