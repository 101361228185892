import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import ReactSlider from 'react-slider';

import {
	GET_BUSINESS_RULE,
	GET_BUSINESS_RULES_ACTION_TYPES,
	GET_BUSINESS_RULES_GLOBAL_ACTIONS,
	GET_BUSINESS_RULES_GLOBAL_FIELDS,
	CREATE_BUSINESS_RULE,
	UPDATE_BUSINESS_RULE,
	GET_BUSINESS_RULES_REPEATING_FIELDS,
} from '../../../shared/api/business-rules';

import id from '../../../shared/utils/id';
import range from '../../../shared/utils/range';
import useChannelQuery from '../../../shared/hooks/useChannelQuery';

import LinearProgress from '../../../shared/components/Progress/LinearProgress';
import CircularProgress from '../../../shared/components/Progress/CircularProgress';
import TrashIcon from '../../../shared/components/Icons/Trash';
import RepeatIcon from '../../../shared/components/Icons/Repeat';
import InfoIcon from '../../../shared/components/Icons/Info';
import Title from '../../../shared/components/Title/Title';
import Text from '../../../shared/components/Text/Text';
import Button from '../../../shared/components/Button/Button';
import IconButton from '../../../shared/components/IconButton/IconButton';
import TextInput from '../../../shared/components/TextInput/TextInput';
import Conditional from '../../../shared/components/Conditional/Conditional';
import InputWithLabel from '../../../shared/components/InputWithLabel/InputWithLabel';
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import { SingleTrack } from '../../../shared/components/Slider/Slider';

import BusinessRulesTagInput from './TagInput';

const mdIncrements = [
	0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
	0.7, 0.75, 0.8, 0.85, 0.9, 0.95,
];

const BusinessRulesCreateEdit = () => {
	const history = useHistory();
	const { businessRuleId } = useParams();
	const isEditMode = !!businessRuleId;
	const [segments, setSegments] = useState({});
	const [possibleDiscounts, setPossibleDiscounts] = useState([...mdIncrements]);
	const [applyRule, setApplyRule] = useState(false);
	const {
		formState,
		setValue,
		control,
		handleSubmit,
		watch,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: {
			title: '',
			action_type: null,
			active: false,
			custom_fixed_action: {
				fixed_discount: 0.0,
			},
			custom_minmax_action: {
				min_discount: null,
				max_discount: null,
			},
			custom_possible_action: {
				markdowns: [],
			},
			custom_average_action: {
				average_discount: 0.5,
			},
			custom_distribution_action: {
				limiter: null,
				distribution: '',
				discount: null,
				type: null,
				field: null,
			},
			custom_min_change_action: {
				min_change_discount: 0.0,
			},
			custom_max_increase_action: {
				max_increase_discount: 0.0,
			},
			global_action_ids: [],
			conditions: [],
		},
	});
	const queryClient = useQueryClient();

	const { data, isFetching, channel } = useChannelQuery(
		['business-rule', businessRuleId],
		() => GET_BUSINESS_RULE(businessRuleId),
		{
			enabled: isEditMode,
			onError: async ({ response }) => {
				if (response?.status === 404) {
					history.push('/strategy/business-rules');
				}
			},
			retry: false,
		}
	);

	useEffect(() => {
		if (data) {
			Object.entries(data).forEach(([key, val]) => {
				if (key === 'global_actions') {
					setValue(
						'global_action_ids',
						val?.map(({ id: aId }) => aId)
					);
				} else if (key === 'conditions') {
					setSegments(val?.reduce((acc, v) => ({ ...acc, [id()]: v }), {}));
				} else if (key === 'custom_possible_action') {
					setPossibleDiscounts(val?.markdowns || []);
				} else if (key === 'custom_distribution_action') {
					setValue(key, {
						...val,
						distribution: val?.distribution * 100 || '',
					});
					setApplyRule(val?.field !== null);
				} else {
					setValue(key, val);
				}
			});
		}
	}, [data]);

	const { data: actionTypes = [], isFetching: isActionTypesFetching } =
		useChannelQuery(
			['business-rules-action-types'],
			GET_BUSINESS_RULES_ACTION_TYPES,
			{
				staleTime: 5 * 60 * 1000,
			}
		);

	const { data: globalActions, isFetching: isGlobalActionsFetching } =
		useChannelQuery(
			['business-rules-globalactions'],
			GET_BUSINESS_RULES_GLOBAL_ACTIONS,
			{
				staleTime: 5 * 60 * 1000,
			}
		);

	const { data: fields, isFetching: isFieldsFetching } = useChannelQuery(
		['business-rules-fields'],
		GET_BUSINESS_RULES_GLOBAL_FIELDS,
		{
			staleTime: 5 * 60 * 1000,
		}
	);

	const { data: repeatingFields, isFetching: isRepeatingFieldsFetching } =
		useChannelQuery(
			['business-rules-repeating-fields'],
			GET_BUSINESS_RULES_REPEATING_FIELDS,
			{
				staleTime: 5 * 60 * 1000,
			}
		);

	const {
		isLoading: isCreateLoading,
		isSuccess: isCreateSuccess,
		mutate: createBusinessRule,
	} = useMutation(CREATE_BUSINESS_RULE, {
		onMutate: async (payload) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries([channel, 'business-rules']);

			// Snapshot the previous value
			const previousBusinessRules = queryClient.getQueryData([
				channel,
				'business-rules',
			]);

			if (previousBusinessRules) {
				// Optimistically update to the new value
				queryClient.setQueryData([channel, 'business-rules'], (old) => {
					return {
						...old,
						items: [
							...(old.items || []),
							{ ...payload, priority: (old.items?.[0]?.priority || 0) + 1 },
						],
						total: old.total + 1,
					};
				});
			}

			// Return a context object with the snapshotted value
			return { previousBusinessRules };
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, payload, context) => {
			queryClient.setQueryData(
				[channel, 'business-rules'],
				context.previousBusinessRules
			);
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries([channel, 'business-rules']);
		},
	});

	const {
		isLoading: isEditLoading,
		isSuccess: isEditSuccess,
		mutate: editBusinessRule,
	} = useMutation((payload) => UPDATE_BUSINESS_RULE(payload.id, payload), {
		onMutate: async (payload) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries([channel, 'business-rules']);

			// Snapshot the previous value
			const previousBusinessRules = queryClient.getQueryData([
				channel,
				'business-rules',
			]);

			if (previousBusinessRules) {
				// Optimistically update to the new value
				queryClient.setQueryData([channel, 'business-rules'], (old) => {
					if (!old) return old;

					return {
						...old,
						items: (old?.items || []).map((item) => {
							if (item.id === payload.id) {
								return {
									...item,
									...payload,
								};
							}

							return item;
						}),
					};
				});
			}

			// Return a context object with the snapshotted value
			return { previousBusinessRules };
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, payload, context) => {
			queryClient.setQueryData(
				[channel, 'business-rules'],
				context.previousBusinessRules
			);
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries([channel, 'business-rules']);
		},
	});

	const watchType = watch('action_type');

	const addSegment = () => {
		setSegments((segs) => ({
			...segs,
			[id()]: { field: null, operator: null, value: null },
		}));
	};

	const editSegment = (sId, newValue) => {
		clearErrors(sId);
		setSegments((segs) => ({ ...segs, [sId]: newValue }));
	};

	const removeSegment = (sId) => {
		clearErrors(sId);
		setSegments((segs) => {
			const { [sId]: segmentToRemove, ...remainingSegments } = segs;

			return remainingSegments;
		});
	};

	const onSubmit = ({
		custom_fixed_action_id: _customFixedActionId,
		custom_minmax_action_id: _customMinMaxActionId,
		custom_possible_action_id: _customPossibleActionId,
		custom_average_action_id: _customAverageActionId,
		custom_distribution_action_id: _customDistributionActionId,
		...d
	}) => {
		clearErrors([
			'custom_minmax_action.min_discount',
			'custom_minmax_action.max_discount',
			'custom_fixed_action.fixed_discount',
			'custom_possible_action.markdowns',
			'custom_average_action.average_discount',
			'custom_average_action.custom_distribution_action',
			'custom_min_change_action.min_change_discount',
			'custom_max_increase_action.max_increase_discount',
		]);

		const errors = [];

		if (
			d?.action_type === 'Custom_minmax' ||
			d?.action_type === 'Custom_fixed' ||
			d?.action_type === 'Custom_min_change' ||
			d?.action_type === 'Custom_max_increase'
		) {
			[
				[
					'custom_minmax_action.min_discount',
					d?.custom_minmax_action?.min_discount,
				],
				[
					'custom_minmax_action.max_discount',
					d?.custom_minmax_action?.max_discount,
				],
				[
					'custom_fixed_action.fixed_discount',
					d?.custom_fixed_action?.fixed_discount,
				],
				[
					'custom_min_change_action.min_change_discount',
					d?.custom_min_change_action?.min_change_discount,
				],
				[
					'custom_max_increase_action.max_increase_discount',
					d?.custom_max_increase_action?.max_increase_discount,
				],
			].forEach(([key, value]) => {
				if (value === undefined || value === null || value === '') {
					return null;
				}

				if (Number.isNaN(parseFloat(value, 10))) {
					return errors.push([
						key,
						{
							type: 'validate',
							message: 'Value must be a valid number',
						},
					]);
				}

				if (value > 0.95 || value < 0) {
					return errors.push([
						key,
						{
							type: 'validate',
							message: 'Value must be between 0 - 95 %',
						},
					]);
				}

				return null;
			});
		}

		if (d?.action_type === 'Custom_minmax') {
			if (
				Number.isNaN(parseFloat(d?.custom_minmax_action?.min_discount, 10)) &&
				Number.isNaN(parseFloat(d?.custom_minmax_action?.max_discount, 10))
			) {
				errors.push(
					[
						'custom_minmax_action.min_discount',
						{
							type: 'validate',
							message: 'Either min./max. or both need to be set',
						},
					],
					[
						'custom_minmax_action.max_discount',
						{
							type: 'validate',
							message: 'Either min./max. or both need to be set',
						},
					]
				);
			}
		}

		if (d?.action_type === 'Custom_possible' && !possibleDiscounts.length) {
			errors.push([
				'custom_possible_action.markdowns',
				{
					type: 'validate',
					message: 'At least 1 markdown must be selected',
				},
			]);
		}

		if (d?.action_type === 'Custom_distribution') {
			const distribution = parseInt(
				d?.custom_distribution_action?.distribution,
				10
			);

			if (
				Number.isNaN(distribution) ||
				distribution < 0 ||
				distribution > 100
			) {
				errors.push([
					'custom_distribution_action.distribution',
					{
						type: 'validate',
						message: 'Value must be between 0 - 100 %',
					},
				]);
			}
		}

		Object.entries(segments).forEach(([key, segment]) => {
			clearErrors(key);
			if (!segment.field) {
				return errors.push([
					key,
					{
						type: 'field',
						message: 'Field is required',
					},
				]);
			}

			if (!segment.operator) {
				return errors.push([
					key,
					{
						type: 'operator',
						message: 'Operator is required',
					},
				]);
			}

			if (!segment.value) {
				return errors.push([
					key,
					{
						type: 'value',
						message: 'Value is required',
					},
				]);
			}

			return null;
		});

		if (d?.action_type === 'Global' && !d?.global_action_ids.length) {
			errors.push([
				'action_type',
				{
					type: 'global',
					message: 'At least 1 action must be selected',
				},
			]);
		}

		if (errors.length) {
			errors.forEach(([key, err]) => setError(key, err));

			// bail out of submission when custom validation errors were triggered
			return null;
		}
		const payload = {
			...d,
			custom_possible_action: {
				markdowns: possibleDiscounts.sort(),
			},
			custom_distribution_action: {
				...d?.custom_distribution_action,
				distribution:
					parseInt(d?.custom_distribution_action?.distribution, 10) / 100,
				field: applyRule ? d?.custom_distribution_action?.field : null,
			},
			conditions: Object.values(segments),
		};
		const finalPayload = {
			title: payload.title,
			tag_id: payload.tag_id,
			action_type: payload.action_type,
			conditions: payload.conditions,
		};
		if (payload.action_type === 'Global')
			finalPayload.global_action_ids = payload.global_action_ids;
		if (payload.action_type === 'Custom_min_change')
			finalPayload.custom_min_change_action = payload.custom_min_change_action;
		if (payload.action_type === 'Custom_max_increase')
			finalPayload.custom_max_increase_action =
				payload.custom_max_increase_action;
		if (payload.action_type === 'Custom_fixed')
			finalPayload.custom_fixed_action = payload.custom_fixed_action;
		if (payload.action_type === 'Custom_minmax')
			finalPayload.custom_minmax_action = payload.custom_minmax_action;
		if (payload.action_type === 'Custom_possible')
			finalPayload.custom_possible_action = payload.custom_possible_action;
		if (payload.action_type === 'Custom_average')
			finalPayload.custom_average_action = payload.custom_average_action;
		if (payload.action_type === 'Custom_distribution')
			finalPayload.custom_distribution_action =
				payload.custom_distribution_action;

		if (payload.id) finalPayload.id = payload.id;
		if (payload.priority) finalPayload.priority = payload.priority;

		return isEditMode
			? editBusinessRule(finalPayload)
			: createBusinessRule(finalPayload);
	};

	const handleAnimationEnded = () => {
		if (isCreateSuccess || isEditSuccess) {
			history.push('/strategy/business-rules');
		}
	};

	const handleActiveMarkdownChange = (md) => {
		if (possibleDiscounts.includes(md)) {
			setPossibleDiscounts((pD) => pD.filter((aMd) => aMd !== md));
		} else {
			setPossibleDiscounts((pD) => [...pD, md]);
		}
	};

	const getOptions = (fField) => {
		const field = fields?.find(({ field: fieldId }) => fField === fieldId);

		if (field?.type === 'Checkbox') {
			return field?.checklist_options?.map((o) => ({
				value: o,
				label: o,
			}));
		}

		if (field?.type === 'List') {
			return field?.list_options?.map((o) => ({
				value: o,
				label: o,
			}));
		}

		return [];
	};

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress
					visible={isCreateLoading || isEditLoading || isFetching}
					onAnimationEnded={handleAnimationEnded}
				/>
			</div>
			<div className="py-6">
				<Title type="section">{isEditMode ? 'Edit rule' : 'New rule'}</Title>
				<form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
					<div className="space-y-5">
						<InputWithLabel
							label="Rule name"
							htmlFor="title"
							labelClassName={clsx(
								'w-64 mb-2 md:mb-0',
								formState?.errors?.title?.message && '-mt-5'
							)}
						>
							<Controller
								name="title"
								control={control}
								rules={{ required: 'Required field' }}
								render={({ field }) => (
									<TextInput
										id="title"
										className="w-full sm:w-64"
										value={field.value}
										onChange={(val) => field.onChange(val)}
										error={formState?.errors?.title?.message}
									/>
								)}
							/>
						</InputWithLabel>
						<InputWithLabel
							label="Tag"
							htmlFor="tag_id"
							labelClassName="w-64 mb-2 md:mb-0"
						>
							<Controller
								name="tag_id"
								control={control}
								render={({ field }) => (
									<BusinessRulesTagInput
										id="tag_id"
										className="w-full sm:w-64"
										value={field.value}
										onChange={(val) => field.onChange(val)}
									/>
								)}
							/>
						</InputWithLabel>
						<Conditional label="IF">
							<InputWithLabel
								labelClassName={clsx(
									'w-60 mb-2 xl:mb-0',
									!!Object.keys(segments).length && 'self-start mt-2.5'
								)}
								label="Segment"
								htmlFor="segment"
							>
								<div className="space-y-5">
									{isFieldsFetching ||
									isRepeatingFieldsFetching ||
									isFetching ? (
										<CircularProgress />
									) : (
										<>
											{!Object.keys(segments).length && (
												<Button variant="unstyled" onClick={addSegment}>
													<Text type="secondary">
														No segment(s) added, click{' '}
														<span className="underline">here</span> to create
														one.
													</Text>
												</Button>
											)}
											{!!Object.keys(segments).length &&
												Object.entries(segments).map(
													([sId, segment], index) => (
														<div
															className="flex flex-col relative pl-13"
															key={sId}
														>
															<div className="w-full flex flex-wrap space-y-2 md:space-y-0 md:space-x-3 xl:w-auto">
																<Dropdown
																	placeholder="Field"
																	className="w-full md:w-48 lg:w-64"
																	value={segment?.field}
																	onChange={(val) =>
																		editSegment(sId, {
																			...segment,
																			field: val,
																		})
																	}
																	options={fields?.map((f) => ({
																		value: f?.field,
																		label: f?.name,
																	}))}
																	error={
																		formState?.errors?.[sId]?.type === 'field'
																	}
																/>
																{segment?.field && (
																	<Dropdown
																		placeholder="Operator"
																		className="w-full md:w-40"
																		value={segment?.operator}
																		onChange={(val) =>
																			editSegment(sId, {
																				...segment,
																				operator: val,
																			})
																		}
																		options={(
																			{
																				Checkbox: [
																					'is in',
																					'is not in',
																					'contains',
																				],
																				List: [
																					'is in',
																					'is not in',
																					'contains',
																				],
																				Range: [
																					'is less than',
																					'is greater than',
																				],
																				Date: ['is before', 'is after'],
																			}?.[
																				fields?.find(
																					({ field }) =>
																						segment?.field === field
																				)?.type
																			] || []
																		).map((o) => ({
																			value: o,
																			label: o,
																		}))}
																		error={
																			formState?.errors?.[sId]?.type ===
																			'operator'
																		}
																	/>
																)}
																{['is less than', 'is greater than'].includes(
																	segment?.operator
																) && (
																	<div>
																		<TextInput
																			placeholder="Value"
																			id={`segment_value_${sId}`}
																			type="number"
																			min="0"
																			step="0.01"
																			className="w-full md:w-48 lg:w-64"
																			value={segment?.value || 0.0}
																			onChange={({ target }) =>
																				editSegment(sId, {
																					...segment,
																					value: parseFloat(target.value),
																				})
																			}
																			error={
																				formState?.errors?.[sId]?.type ===
																				'value'
																			}
																		/>
																	</div>
																)}
																{['is before', 'is after'].includes(
																	segment?.operator
																) && (
																	<div>
																		<TextInput
																			placeholder="Date"
																			type="date"
																			id={`segment_value_${sId}`}
																			className="w-full md:w-48 lg:w-64"
																			value={segment?.value || new Date()}
																			onChange={({ target }) =>
																				editSegment(sId, {
																					...segment,
																					value: target.value,
																				})
																			}
																			error={
																				formState?.errors?.[sId]?.type ===
																				'value'
																			}
																		/>
																	</div>
																)}
																{['is in', 'is not in'].includes(
																	segment?.operator
																) && (
																	<Dropdown
																		placeholder="Value"
																		className="w-full md:w-48 lg:w-64"
																		multiple
																		value={segment?.value}
																		onChange={(val) =>
																			editSegment(sId, {
																				...segment,
																				value: val,
																			})
																		}
																		options={getOptions(segment?.field)}
																		error={
																			formState?.errors?.[sId]?.type === 'value'
																		}
																	/>
																)}
																{['contains'].includes(segment?.operator) && (
																	<TextInput
																		placeholder="Value"
																		id={`segment_value_${sId}`}
																		className="w-full md:w-48 lg:w-64"
																		value={segment?.value || ''}
																		onChange={({ target }) =>
																			editSegment(sId, {
																				...segment,
																				value: target.value,
																			})
																		}
																		error={
																			formState?.errors?.[sId]?.type === 'value'
																		}
																	/>
																)}
																<div className="w-full flex items-center justify-center lg:w-auto md:justify-start">
																	<div className="inline-flex">
																		<IconButton
																			icon={TrashIcon}
																			tooltip="Remove condition"
																			className="h-5"
																			onClick={() => removeSegment(sId)}
																		/>
																	</div>
																</div>
																{index > 0 && (
																	<div className="hidden lg:block lg:w-auto lg:absolute lg:transform lg:-translate-x-full lg:-left-6">
																		<div className="text-sm text-ca-gray font-bold bg-ca-silver p-2.5 rounded-lg">
																			AND
																		</div>
																	</div>
																)}
															</div>
															{formState?.errors?.[sId]?.message && (
																<div className="w-full mt-1 pr-14 text-ca-red text-xs text-right">
																	{formState?.errors?.[sId]?.message}
																</div>
															)}
														</div>
													)
												)}
											{!!Object.keys(segments).length && (
												<div className="md:mr-14 md:w-48 lg:w-64">
													<Button
														variant="unstyled"
														className={clsx(
															'w-full p-3 rounded-lg text-ca-purple text-sm font-bold border-2 border-dashed border-ca-purple text-center transition-colors',
															'focus-visible:border-ca-purple-a focus-visible:text-ca-purple-a focus-visible:bg-ca-purple focus-visible:bg-opacity-10',
															'hover:border-ca-purple-a hover:text-ca-purple-a hover:bg-ca-purple hover:bg-opacity-10',
															'active:text-ca-purple-a active:border-ca-purple-a active:bg-ca-purple active:bg-opacity-10'
														)}
														onClick={addSegment}
													>
														Add another segment
													</Button>
												</div>
											)}{' '}
										</>
									)}
								</div>
							</InputWithLabel>
						</Conditional>
						<Conditional label="THEN">
							<InputWithLabel
								labelClassName={clsx(
									'w-60 mb-2 md:mb-0',
									formState?.errors?.action_type?.message && '-mt-5'
								)}
								label="Type of rule"
								htmlFor="action_type"
							>
								{isActionTypesFetching ? (
									<CircularProgress />
								) : (
									<>
										<Controller
											name="action_type"
											control={control}
											rules={{ required: 'Required field' }}
											render={({ field }) => (
												<Dropdown
													placeholder="Choose"
													className="w-64"
													maxHeight={64}
													value={field.value}
													onChange={(val) => field.onChange(val)}
													options={[
														{
															value: 'Custom_minmax',
															label: 'Min/max. discount',
														},
														{ value: 'Custom_fixed', label: 'Fixed discount' },
														{
															value: 'Custom_min_change',
															label: 'Minimal change discount',
														},
														{
															value: 'Custom_max_increase',
															label: 'Maximal increase discount',
														},
														{
															value: 'Custom_possible',
															label: 'Possible discounts',
														},
														{
															value: 'Custom_average',
															label: 'Average discount',
														},
														{
															value: 'Custom_distribution',
															label: 'Discount distribution',
														},
														{ value: 'Global', label: 'Built-in rules' },
													].filter(({ value }) => actionTypes.includes(value))}
													error={formState?.errors?.action_type?.message}
												/>
											)}
										/>
										{watchType === 'Global' && (
											<div className="mt-4 space-y-3 w-full md:ml-60">
												{isGlobalActionsFetching ? (
													<CircularProgress />
												) : (
													<Controller
														name="global_action_ids"
														control={control}
														render={({ field }) => (
															<>
																{globalActions?.items?.map((action) => (
																	<Checkbox
																		key={action?.id}
																		checked={field.value?.includes(action?.id)}
																		onChange={(val) => {
																			if (val) {
																				field.onChange([
																					...field.value,
																					action?.id,
																				]);
																			} else {
																				field.onChange(
																					field.value.filter(
																						(aId) => aId !== action?.id
																					)
																				);
																			}
																		}}
																		label={action?.description}
																	/>
																))}
															</>
														)}
													/>
												)}
											</div>
										)}
										{watchType === 'Custom_minmax' && (
											<div className="w-full">
												<div className="inline-block mt-5 space-y-5">
													<InputWithLabel
														label="Minimum discount"
														htmlFor="custom_minmax_action.min_discount"
														className="justify-between"
														labelClassName="w-60 mb-2 md:mb-0"
													>
														<Controller
															name="custom_minmax_action.min_discount"
															control={control}
															render={({ field }) => (
																<>
																	<Dropdown
																		id="custom_minmax_action.min_discount"
																		placeholder="Choose"
																		className="w-full md:w-48 lg:w-64"
																		value={field.value}
																		onChange={(val) => field.onChange(val)}
																		options={range(0, 95, 5).map((v) => ({
																			value: v / 100,
																			label: `${v}%`,
																		}))}
																		error={
																			formState?.errors?.custom_minmax_action
																				?.min_discount?.message
																		}
																	/>
																	<div className="flex items-center px-2 space-x-5">
																		<div className="inline-flex">
																			<IconButton
																				icon={RepeatIcon}
																				tooltip="Reset"
																				className="h-5"
																				onClick={() => field.onChange(null)}
																			/>
																		</div>
																	</div>
																</>
															)}
														/>
													</InputWithLabel>
													<InputWithLabel
														label="Maximum discount"
														htmlFor="custom_minmax_action.max_discount"
														className="justify-between"
														labelClassName={clsx(
															'w-60 mb-2 md:mb-0',
															formState?.errors?.custom_minmax_action
																?.max_discount?.message && '-mt-5'
														)}
													>
														<Controller
															name="custom_minmax_action.max_discount"
															control={control}
															render={({ field }) => (
																<>
																	<Dropdown
																		id="custom_minmax_action.max_discount"
																		placeholder="Choose"
																		className="w-full md:w-48 lg:w-64"
																		value={field.value}
																		onChange={(val) => field.onChange(val)}
																		options={range(0, 95, 5).map((v) => ({
																			value: v / 100,
																			label: `${v}%`,
																		}))}
																		error={
																			formState?.errors?.custom_minmax_action
																				?.max_discount?.message
																		}
																	/>
																	<div className="flex items-center px-2 space-x-5">
																		<div className="inline-flex">
																			<IconButton
																				icon={RepeatIcon}
																				tooltip="Reset"
																				className="h-5"
																				onClick={() => field.onChange(null)}
																			/>
																		</div>
																	</div>
																</>
															)}
														/>
													</InputWithLabel>
												</div>
											</div>
										)}
										{watchType === 'Custom_fixed' && (
											<div className="w-full">
												<div className="inline-block mt-5">
													<InputWithLabel
														label="Fixed discount"
														htmlFor="custom_fixed_action.fixed_discount"
														className="justify-between"
														labelClassName={clsx(
															'w-60 mb-2 md:mb-0',
															formState?.errors?.custom_fixed_action
																?.fixed_discount?.message && '-mt-5'
														)}
													>
														<Controller
															name="custom_fixed_action.fixed_discount"
															control={control}
															rules={{ required: 'Required field' }}
															render={({ field }) => (
																<Dropdown
																	id="custom_fixed_action.fixed_discount"
																	placeholder="Choose"
																	className="w-full md:w-48 lg:w-64"
																	value={field.value}
																	onChange={(val) => field.onChange(val)}
																	options={range(0, 95, 5).map((v) => ({
																		value: v / 100,
																		label: `${v}%`,
																	}))}
																	error={
																		formState?.errors?.custom_fixed_action
																			?.fixed_discount?.message
																	}
																/>
															)}
														/>
													</InputWithLabel>
												</div>
											</div>
										)}
										{watchType === 'Custom_min_change' && (
											<div className="w-full">
												<div className="inline-block mt-5">
													<InputWithLabel
														label="Minimal change in discount"
														htmlFor="custom_min_change_action.min_change_discount"
														className="justify-between"
														labelClassName={clsx(
															'w-60 mb-2 md:mb-0',
															formState?.errors?.custom_min_change_action
																?.min_change_discount?.message && '-mt-5'
														)}
													>
														<Controller
															name="custom_min_change_action.min_change_discount"
															control={control}
															rules={{ required: 'Required field' }}
															render={({ field }) => (
																<Dropdown
																	id="custom_min_change_action.min_change_discount"
																	placeholder="Choose"
																	className="w-full md:w-48 lg:w-64"
																	value={field.value}
																	onChange={(val) => field.onChange(val)}
																	options={range(0, 95, 5).map((v) => ({
																		value: v / 100,
																		label: `${v}%`,
																	}))}
																	error={
																		formState?.errors?.custom_min_change_action
																			?.min_change_discount?.message
																	}
																/>
															)}
														/>
													</InputWithLabel>
												</div>
											</div>
										)}
										{watchType === 'Custom_max_increase' && (
											<div className="w-full">
												<div className="inline-block mt-5">
													<InputWithLabel
														label="Maximal increase in discount"
														htmlFor="custom_max_increase_action.max_increase_discount"
														className="justify-between"
														labelClassName={clsx(
															'w-60 mb-2 md:mb-0',
															formState?.errors?.custom_max_increase_action
																?.max_increase_discount?.message && '-mt-5'
														)}
													>
														<Controller
															name="custom_max_increase_action.max_increase_discount"
															control={control}
															rules={{ required: 'Required field' }}
															render={({ field }) => (
																<Dropdown
																	id="custom_max_increase_action.max_increase_discount"
																	placeholder="Choose"
																	className="w-full md:w-48 lg:w-64"
																	value={field.value}
																	onChange={(val) => field.onChange(val)}
																	options={range(0, 95, 5).map((v) => ({
																		value: v / 100,
																		label: `${v}%`,
																	}))}
																	error={
																		formState?.errors
																			?.custom_max_increase_action
																			?.max_increase_discount?.message
																	}
																/>
															)}
														/>
													</InputWithLabel>
												</div>
											</div>
										)}
										{watchType === 'Custom_possible' && (
											<div className="w-full">
												<div className="inline-block mt-5">
													<InputWithLabel
														htmlFor="custom_possible_action.markdowns"
														label="Markdowns"
														id="custom_possible_action.markdowns"
														labelClassName={clsx('w-60 mb-2 md:mb-0')}
													>
														<div className="grid grid-cols-5 gap-y-1 gap-x-8">
															{mdIncrements.map((increment) => (
																<Checkbox
																	key={increment}
																	checked={possibleDiscounts.includes(
																		increment
																	)}
																	onChange={() => {
																		clearErrors(
																			'custom_possible_action.markdowns'
																		);
																		handleActiveMarkdownChange(increment);
																	}}
																	label={`${Math.round(increment * 100)}%`}
																/>
															))}
														</div>
													</InputWithLabel>
													{formState?.errors?.custom_possible_action?.markdowns
														?.message && (
														<div className="w-full mt-2 text-ca-red text-xs text-right">
															{
																formState?.errors?.custom_possible_action
																	?.markdowns?.message
															}
														</div>
													)}
												</div>
											</div>
										)}
										{watchType === 'Custom_average' && (
											<div className="w-full">
												<div className="inline-block mt-5">
													<InputWithLabel
														htmlFor="custom_average_action.average_discount"
														label="Discount"
														id="custom_average_action.average_discount"
														labelClassName={clsx('w-60 mb-2 md:mb-0')}
													>
														<Controller
															name="custom_average_action.average_discount"
															control={control}
															rules={{ required: 'Required field' }}
															render={({ field }) => (
																<div className="relative flex flex-wrap justify-center w-64">
																	<span className="absolute -left-1.5 transform -translate-x-full top-3.5 text-xs text-ca-gray">
																		0%
																	</span>
																	<ReactSlider
																		className="w-full mt-4 mb-8"
																		min={0}
																		max={100}
																		value={field.value * 100}
																		renderTrack={SingleTrack}
																		renderThumb={({ key, ...props }, state) => (
																			<div key={key}>
																				<div
																					{...props}
																					className="w-4 h-4 bg-white border-2 border-ca-purple rounded-full cursor-grab focus-visible:outline-none"
																				/>
																				{state.valueNow > 0 &&
																					state.valueNow < 100 && (
																						<span
																							className="top-5 text-xs text-ca-gray"
																							// eslint-disable-next-line react/prop-types
																							style={props.style}
																						>
																							{state.valueNow}%
																						</span>
																					)}
																			</div>
																		)}
																		onChange={(value, ...rest) =>
																			field.onChange(value / 100, ...rest)
																		}
																	/>
																	<span className="absolute -right-1.5 transform translate-x-full top-3.5 text-xs text-ca-gray">
																		100%
																	</span>
																</div>
															)}
														/>
													</InputWithLabel>
												</div>
											</div>
										)}
										{watchType === 'Custom_distribution' && (
											<div className="w-full">
												<div className="inline-block mt-5 space-y-5">
													<InputWithLabel
														htmlFor="custom_distribution_action.distribution"
														label="Distribution"
														id="custom_distribution_action.distribution"
														labelClassName="w-60 mb-2 md:mb-0"
													>
														<Controller
															name="custom_distribution_action.limiter"
															control={control}
															rules={{ required: 'Required field' }}
															render={({ field }) => (
																<Dropdown
																	id="custom_distribution_action.limiter"
																	placeholder="Min/max"
																	className="w-full md:w-32 md:mr-3"
																	value={field.value}
																	onChange={(val) => field.onChange(val)}
																	options={[
																		{
																			label: 'Min.',
																			value: 'min',
																		},
																		{
																			label: 'Max.',
																			value: 'max',
																		},
																	]}
																	error={
																		!!formState?.errors
																			?.custom_distribution_action?.limiter
																			?.message
																	}
																/>
															)}
														/>
														<Controller
															name="custom_distribution_action.distribution"
															control={control}
															rules={{
																required: 'Required field',
															}}
															render={({ field }) => (
																<TextInput
																	id="title"
																	className="w-full md:w-24"
																	value={field.value}
																	onChange={(val) => field.onChange(val)}
																	error={
																		!!formState?.errors
																			?.custom_distribution_action?.distribution
																			?.message
																	}
																	endAdornment="%"
																/>
															)}
														/>
														<Text
															className="my-4 md:my-0 md:mx-3"
															type="secondary"
														>
															of
														</Text>
														<Controller
															name="custom_distribution_action.type"
															control={control}
															rules={{ required: 'Required field' }}
															render={({ field }) => (
																<Dropdown
																	id="custom_distribution_action.type"
																	placeholder="Type"
																	className="w-full md:w-28"
																	value={field.value}
																	onChange={(val) => field.onChange(val)}
																	options={[
																		{
																			value: 'products',
																			label: 'Products',
																		},
																		{
																			value: 'stock',
																			label: 'Stock',
																		},
																	]}
																	error={
																		!!formState?.errors
																			?.custom_distribution_action?.type
																			?.message
																	}
																/>
															)}
														/>
														<Text
															className="my-4 md:my-0 md:mx-3"
															type="secondary"
														>
															should be discounted at
														</Text>
														<Controller
															name="custom_distribution_action.discount"
															control={control}
															rules={{ required: 'Required field' }}
															render={({ field }) => (
																<Dropdown
																	id="custom_distribution_action.discount"
																	placeholder="%"
																	className="w-full md:w-28"
																	value={field.value}
																	onChange={(val) => field.onChange(val)}
																	options={range(0, 95, 5).map((v) => ({
																		value: v / 100,
																		label: `${v}%`,
																	}))}
																	error={
																		!!formState?.errors
																			?.custom_distribution_action?.discount
																			?.message
																	}
																/>
															)}
														/>
													</InputWithLabel>
													<InputWithLabel
														htmlFor="custom_distribution_action.field"
														label={
															<Tooltip content="The rule is only applied to groups that represent at least 1% of the total amount of stock.">
																<div className="flex items-center">
																	Field
																	<span className="inline-block ml-1.5">
																		<InfoIcon className="h-3.5" />
																	</span>
																</div>
															</Tooltip>
														}
														id="custom_distribution_action.field"
														labelClassName="w-60 mb-2 md:mb-0"
													>
														<Checkbox
															checked={applyRule}
															onChange={() => setApplyRule(!applyRule)}
															label="Apply rule for every "
														/>
														<Controller
															name="custom_distribution_action.field"
															control={control}
															rules={{
																required: applyRule ? 'Required field' : false,
															}}
															render={({ field }) => (
																<Dropdown
																	placeholder="Field"
																	className="w-full md:ml-3 md:w-48 lg:w-64"
																	value={field.value}
																	onChange={(val) => field.onChange(val)}
																	options={repeatingFields?.map((f) => ({
																		value: f?.field,
																		label: f?.name,
																	}))}
																	error={
																		!!formState?.errors
																			?.custom_distribution_action?.field
																			?.message
																	}
																/>
															)}
														/>
													</InputWithLabel>
												</div>
											</div>
										)}
									</>
								)}
							</InputWithLabel>
						</Conditional>
					</div>
					<div className="mt-6 space-x-5">
						<Button
							type="submit"
							disabled={
								isCreateLoading ||
								isEditLoading ||
								isCreateSuccess ||
								isEditSuccess
							}
						>
							Save
						</Button>
						<Button
							variant="link"
							onClick={() => history.push('/strategy/business-rules')}
						>
							Cancel
						</Button>
					</div>
				</form>
			</div>
		</>
	);
};

export default BusinessRulesCreateEdit;
