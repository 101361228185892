/**
 * ------------------------------
 * ---------- MODELS ----------
 * ------------------------------
 */

interface BaseEntity {
	id: string;
}

type DateString = string;

export enum SortOrderDTO {
	Ascending = 'asc',
	Descending = 'desc',
}

export enum FilterTypeDTO {
	List = 'List',
	Range = 'Range',
	Checkbox = 'Checkbox',
}

export interface FilterDTO extends BaseEntity {
	name: string;
	field: string;
	type: FilterTypeDTO;
	list_options: [];
	checklist_options: [];
	range_options: [];
	percentage: boolean;
	is_hidden: boolean;
}

export interface ColumnDTO<FieldType> extends BaseEntity {
	column_id: string;
	name: string;
	field_type: FieldType;
	order: SortOrderDTO;
	order_bq_name: string;
	bq_name: string;
	combined_bq_name: string;
	tooltip: string;
	sortable: boolean;
	hidden_by_default: boolean;
	unit: string;
	add_unit: boolean;
}

/**
 * ---------- PRODUCT ----------
 */

export interface ProductDTO extends BaseEntity {
	product_id: string;
	description: string;
	brand: string;
	collection: string;
	season: string;
	target: string;
	product_group: [];
	price: string;
	cost: string;
	image_url: string;
	sku_purchase_cost: string;
	item_id: string;
	variant_code: string;
	variant_id: string;
	product_year_season: string;
	product_color: string;
	item_no_: string;
	sku_id: string;
	sku_size: string;
	product_collection_code: string;
}

export enum ProductFieldTypeDTO {
	Id = 'id',
	Normal = 'normal',
	CurrentStock = 'current-stock',
	OptimalStock = 'optimal-stock',
	Image = 'image',
}

export type ProductColumnDTO = ColumnDTO<ProductFieldTypeDTO>;

/**
 * ---------- STOCK LOCATION ----------
 */

export interface StockLocationDTO {
	stock_location_id: string;
	stock_location_name: string;
	physical: string;
	urbanization: string;
	/** Warning: `sender` is not available yet in the API. */
	sender: boolean;
	/** Warning: `receiver` is not available yet in the API. */
	receiver: boolean;
}

export enum StockLocationFieldTypeDTO {
	Id = 'id',
	Normal = 'normal',
}

export type StockLocationColumnDTO = ColumnDTO<StockLocationFieldTypeDTO>;

/**
 * ---------- SCOPE ----------
 */

export enum ScopeTypeDTO {
	ProductScope = 'include_products',
	LocationScope = 'include_shops',
}

interface BaseScope {
	scope_type: ScopeTypeDTO;
}

export interface ProductScopeDTO extends BaseScope {
	scope_type: ScopeTypeDTO.ProductScope;
	products_to_include: ProductDTO['id'][];
}

export interface LocationScopeDTO extends BaseScope {
	scope_type: ScopeTypeDTO.LocationScope;
	shops_to_include: StockLocationDTO['stock_location_id'][];
}

export type ScopeDTO = LocationScopeDTO | ProductScopeDTO;

/**
 * ---------- CONSTRAINTS ----------
 */

export enum ConstraintTypeDTO {
	MaximumVolumeMoved = 'maximum_volume_moved',
	MinimumShipmentAmount = 'minimum_shipment_amount',
	MaximumShipmentAmount = 'maximum_shipment_amount',
	CooldownPeriod = 'cooldown_period',
	MinimumROI = 'minimum_roi',
	DoNotBreakSizeCharts = 'do_not_break_size_chart',
	ExcludeProducts = 'exclude_products_from_channels',
	Capacity = 'capacity',
	OnlyRestoreSizeCharts = 'only_restore_size_charts',
}

interface BaseConstraint {
	constraint_type: ConstraintTypeDTO;
	lost_revenue: number;
	/** Warning: `scopes` is not available yet in the API. */
	scopes: ScopeDTO[];
}

export interface MaximumVolumeMovedConstraintDTO extends BaseConstraint {
	constraint_type: ConstraintTypeDTO.MaximumVolumeMoved;
	maximum_volume_moved: number;
}

export interface CooldownPeriodConstraintDTO extends BaseConstraint {
	constraint_type: ConstraintTypeDTO.CooldownPeriod;
	cooldown_period_days: number;
}

export interface MinimumROIConstraintDTO extends BaseConstraint {
	constraint_type: ConstraintTypeDTO.MinimumROI;
	minimum_roi: number;
}

export interface MinimumShipmentAmountConstraintDTO extends BaseConstraint {
	constraint_type: ConstraintTypeDTO.MinimumShipmentAmount;
	minimum_shipment_amount: number;
}

export interface MaximumShipmentAmountConstraintDTO extends BaseConstraint {
	constraint_type: ConstraintTypeDTO.MaximumShipmentAmount;
	maximum_shipment_amount: number;
}

export interface DoNotBreakSizeChartsConstraintDTO extends BaseConstraint {
	constraint_type: ConstraintTypeDTO.DoNotBreakSizeCharts;
	/** QUESTION: Is this one necessary? Can't we consider the constraint to be active as soon as it's added to the report? */
	cannot_break_size_chart: boolean;
}

/** QUESTION: What does this constraint do exactly? */
export interface ExcludeProductsForChannelsConstraintDTO
	extends BaseConstraint {
	constraint_type: ConstraintTypeDTO.ExcludeProducts;
	active: boolean;
}

export interface CapacityConstraintDTO extends BaseConstraint {
	constraint_type: ConstraintTypeDTO.Capacity;
	activate_capacity_constraint: boolean;
}

export interface OnlyRestoreSizeChartsConstraintDTO extends BaseConstraint {
	constraint_type: ConstraintTypeDTO.OnlyRestoreSizeCharts;
	activate_only_restore_size_charts_constraint: boolean;
}

export type ConstraintDTO =
	| MaximumVolumeMovedConstraintDTO
	| MinimumShipmentAmountConstraintDTO
	| MaximumShipmentAmountConstraintDTO
	| DoNotBreakSizeChartsConstraintDTO
	| CooldownPeriodConstraintDTO
	| MinimumROIConstraintDTO
	| ExcludeProductsForChannelsConstraintDTO
	| CapacityConstraintDTO
	| OnlyRestoreSizeChartsConstraintDTO;

/**
 * ---------- SECONDARY OBJECTIVES ----------
 */

export enum SecondaryObjectiveTypeDTO {
	FixBrokenSizeChart = 'fix_broken_size_charts',
	PenalizeBrokenSizeCharts = 'penalize_broken_size_charts',
	Unknown = 'unknown',
}

interface BaseSecondaryObjective {
	secondary_objective_type: SecondaryObjectiveTypeDTO;
	lost_revenue: number;
	/** Warning: `scopes` is not available yet in the API. */
	scopes: ScopeDTO[];
	/** Warning: `weight` is not available yet in the API. */
	weight: number;
}

export interface FixBrokenSizeChartSecondaryObjectiveDTO
	extends BaseSecondaryObjective {
	secondary_objective_type: SecondaryObjectiveTypeDTO.FixBrokenSizeChart;
	description: string;
}

export interface PenalizeBrokenSizeChartsSecondaryObjectiveDTO
	extends BaseSecondaryObjective {
	secondary_objective_type: SecondaryObjectiveTypeDTO.PenalizeBrokenSizeCharts;
}

export type SecondaryObjectiveDTO =
	| FixBrokenSizeChartSecondaryObjectiveDTO
	| PenalizeBrokenSizeChartsSecondaryObjectiveDTO;

/**
 * ---------- MARKETING ACTIONS ----------
 */

export enum MarketingActionTypeDTO {
	IncreaseDemand = 'increase_demand',
}

interface BaseMarketingAction {
	marketing_action_type: MarketingActionTypeDTO;
}

export interface ExpectedIncreaseDemandMarketingActionDTO
	extends BaseMarketingAction {
	marketing_action_type: MarketingActionTypeDTO;
	products_in_marketing_action: ProductDTO['id'][];
	expected_sales_uplift: number;
	predicted_sales_uplift: number;
	sales_multiplication_factor: number;
	name_marketing_action: string;
}

export type MarketingActionDTO = ExpectedIncreaseDemandMarketingActionDTO;

/**
 * ---------- REPORT ----------
 */

export enum ReportStatusDTO {
	Draft = 'Draft',
	InProgress = 'In Progress',
	Cancelled = 'Cancelled',
	Proposal = 'Proposal',
	Submitted = 'Submitted',
	Executed = 'Executed',
	NoSuggestedMoves = 'No Suggested Moves',
	Error = 'Error',
}

export interface ReportDTO extends BaseEntity {
	name: string;
	scopes: ScopeDTO[] | null;
	constraints: ConstraintDTO[] | null;
	secondary_objectives: SecondaryObjectiveDTO[] | null;
	marketing_actions: MarketingActionDTO[] | null;
	date_created: DateString;
	date_updated: DateString;
	timestamp_last_update: DateString;
	status: ReportStatusDTO;
	allocation_id: InventoryAllocationDTO['id'] | null;
	possible_revenue_gain: number;
	proposed_number_of_movements: number;
	proposed_number_of_senders: number;
	proposed_number_of_receivers: number;
	message: string | null;
}

/**
 * ---------- INVENTORY ALLOCATION ----------
 */

export enum InventoryAllocationStatusDTO {
	Draft = 'Draft',
	InProgress = 'In Progress',
	Cancelled = 'Cancelled',
	Proposal = 'Proposal',
	Submitted = 'Submitted',
	Executed = 'Executed',
	Error = 'Error',
	NoSuggestedMoves = 'No Suggested Moves',
}

export interface InventoryAllocationDTO extends BaseEntity {
	name: string;
	date_created: DateString;
	report_ids: ReportDTO['id'][];
	submitted_report: ReportDTO['id'] | null;
	status: InventoryAllocationStatusDTO;
	date_updated: DateString;
	timestamp_last_update: DateString;
}

/**
 * ---------- ENGINE ----------
 */

export enum EngineStatusDTO {
	Initiated = 'initiated',
	Preprocessed = 'preprocessed',
	Built = 'built',
	Solved = 'solved',
	Postprocessed = 'postprocessed',
}

/**
 * ------------------------------
 * ---------- REQUESTS ----------
 * ------------------------------
 */

export interface PaginatedParametersDTO {
	/** @default 1 */
	page: number;
	/** @default 50 */
	size: number;
}

export interface PaginatedResponseDTO<EntityDTO> {
	items: EntityDTO[];
	total: number;
	page: number;
	size: number;
	pages: number;
}

/**
 * ---------- GET /api/v1/constraints
 */

export type GetConstraintsParametersDTO = PaginatedParametersDTO;
export type GetConstraintsBodyDTO = {};
export type GetConstraintsResultsDTO = PaginatedResponseDTO<ConstraintDTO>;

/**
 * ---------- REPORTS ----------
 */

/**
 * ---------- POST /api/v1/reports/{allocation_id} ----------
 */

export type CreateReportParametersDTO = {
	allocation_id: InventoryAllocationDTO['id'];
};
export interface CreateReportBodyDTO {
	name: ReportDTO['name'];
	scopes: ReportDTO['scopes'];
	constraints: ConstraintDTO[];
	secondary_objectives: SecondaryObjectiveDTO[];
	marketing_actions: MarketingActionDTO[];
}
export type CreateReportResponseDTO = ReportDTO;

/**
 * ---------- GET /api/v1/allocation/reports/{allocation_id} ----------
 */

/**
 * QUESTION: Is there a way to get all reports for a specific `inventory-allocation`?
 * To render all drafts for example.
 */
export interface GetReportsParametersDTO extends PaginatedParametersDTO {
	allocation_id: InventoryAllocationDTO['id'];
}
export type GetReportsBodyDTO = {};
export type GetReportsResponseDTO = PaginatedResponseDTO<ReportDTO>;

/**
 * ---------- GET /api/v1/reports/{allocation_id}/{report_id} ----------
 */

export interface GetReportParametersDTO {
	allocation_id: InventoryAllocationDTO['id'];
	report_id: ReportDTO['id'];
}
export type GetReportBodyDTO = {};
export type GetReportResponseDTO = ReportDTO;

/**
 * ---------- GET /api/v1/reports/constraints ----------
 */

export type GetReportConstraintsParametersDTO = PaginatedParametersDTO;
export type GetReportConstraintsBodyDTO = {};
export type GetReportConstraintsResponseDTO = PaginatedResponseDTO<
	Pick<ConstraintDTO, 'constraint_type' | 'lost_revenue'>
>;

/**
 * ---------- GET /api/v1/reports/secondary_objectives ----------
 */

export type GetReportSecondaryObjectivesParametersDTO = PaginatedParametersDTO;
export type GetReportSecondaryObjectivesBodyDTO = {};
export type GetReportSecondaryObjectivesResponseDTO = PaginatedResponseDTO<
	Pick<SecondaryObjectiveDTO, 'secondary_objective_type' | 'lost_revenue'>
>;

/**
 * ---------- PATCH /api/v1/reports/{allocation_id}/{report_id} ----------
 */

export interface UpdateReportParametersDTO {
	allocation_id: InventoryAllocationDTO['id'];
	report_id: ReportDTO['id'];
}
export interface UpdateReportBodyDTO {
	name: ReportDTO['name'];
	scopes?: ReportDTO['scopes'];
	constraints?: ConstraintDTO[];
	secondary_objectives?: ReportDTO['secondary_objectives'];
	marketing_actions?: ReportDTO['marketing_actions'];
	status: ReportDTO['status'];
}
export type UpdateReportResponseDTO = ReportDTO;

/**
 * ---------- INVENTORY ALLOCATIONS ----------
 */

/**
 * ---------- GET /api/v1/allocations ----------
 */

export type GetInventoryAllocationsParametersDTO = PaginatedParametersDTO;
export type GetInventoryAllocationsBodyDTO = {};
export type GetInventoryAllocationsResponseDTO =
	PaginatedResponseDTO<InventoryAllocationDTO>;

/**
 * ---------- GET /api/v1/allocations/{allocation_id} ----------
 */

export interface GetInventoryAllocationParametersDTO {
	allocation_id: InventoryAllocationDTO['id'];
}
export type GetInventoryAllocationBodyDTO = {};
export type GetInventoryAllocationResponseDTO = InventoryAllocationDTO;

/**
 * ---------- POST /api/v1/allocations ----------
 */

export type CreateInventoryAllocationParametersDTO = {};
export interface CreateInventoryAllocationBodyDTO {
	name: InventoryAllocationDTO['name'];
}
export type CreateInventoryAllocationResponseDTO = InventoryAllocationDTO;

/**
 * ---------- PUT /api/v1/allocations/{allocation_id}
 */

export interface UpdateInventoryAllocationParametersDTO {
	allocation_id: InventoryAllocationDTO['id'];
}
export interface UpdateInventoryAllocationBodyDTO {
	name?: InventoryAllocationDTO['name'];
	status: InventoryAllocationDTO['status'];
	report_id_to_add?: ReportDTO['id'];
	report_id_to_remove?: ReportDTO['id'];
	submitted_report?: ReportDTO['id'];
}
export type UpdateInventoryAllocationResponseDTO = InventoryAllocationDTO;

/**
 * ---------- DELETE /api/v1/allocations/{allocation_id}
 */

export interface DeleteInventoryAllocationParametersDTO {
	allocation_id: InventoryAllocationDTO['id'];
}
export type DeleteInventoryAllocationBodyDTO = {};
export type DeleteInventoryAllocationResponseDTO = {
	success: boolean;
};

/**
 * ---------- POST /api/v1/allocations/{allocation_id}/reports/{report_id}
 */

export interface SubmitInventoryAllocationParametersDTO {
	allocation_id: InventoryAllocationDTO['id'];
	report_id: ReportDTO['id'];
}
export type SubmitInventoryAllocationBodyDTO = {};
export type SubmitInventoryAllocationResponseDTO = InventoryAllocationDTO;

/**
 * ---------- PRODUCTS ----------
 */

/**
 * ---------- GET /api/v1/products/filters ----------
 */

export type GetProductsFiltersParametersDTO = {};
export type GetProductsFiltersBodyDTO = {};
export type GetProductsFiltersResponseDTO = FilterDTO[];

/**
 * ---------- GET /api/v1/products/schema ----------
 */

export type GetProductsSchemaParametersDTO = {};
export type GetProductsSchemaBodyDTO = {};
export type GetProductsSchemaResponseDTO = ProductColumnDTO[];

/**
 * ---------- GET /api/v1/products ----------
 */

export interface GetProductsParametersDTO extends PaginatedParametersDTO {
	report_id: ReportDTO['id'];
}
export type GetProductsBodyDTO = {};
export interface GetProductsResponseDTO
	extends PaginatedResponseDTO<ProductDTO> {
	report_id: ReportDTO['id'];
}

/**
 * ---------- GET /api/v1/products/ids ----------
 */

export interface GetProductIdsParametersDTO {
	filter: string[];
}
export type GetProductIdsBodyDTO = {};
export type GetProductIdsResponseDTO = ProductDTO['id'][];

/**
 * ---------- LOCATIONS ----------
 */

/**
 * ---------- GET /api/v1/locations/filters ----------
 */

export type GetLocationsFiltersParametersDTO = {};
export type GetLocationsFiltersBodyDTO = {};
export type GetLocationsFiltersResponseDTO = FilterDTO[];

/**
 * ---------- GET /api/v1/locations/schema ----------
 */

export type GetLocationsSchemaParametersDTO = {};
export type GetLocationsSchemaBodyDTO = {};
export type GetLocationsSchemaResponseDTO = StockLocationColumnDTO[];

/**
 * ---------- GET /api/v1/locations ----------
 */

export interface GetLocationsParametersDTO extends PaginatedParametersDTO {
	report_id: ReportDTO['id'];
}
export type GetLocationsBodyDTO = {};
export interface GetLocationsResponseDTO
	extends PaginatedResponseDTO<StockLocationDTO> {
	report_id: ReportDTO['id'];
}

/**
 * ---------- GET /api/v1/locations/ids ----------
 */

export interface GetLocationsIdsParametersDTO {
	filter: string[];
}
export type GetLocationsIdsBodyDTO = {};
export type GetLocationsIdsResponseDTO = ProductDTO['id'][];

/**
 * ---------- GET /api/v1/dashboards/cumulio/test ----------
 */

export interface GetReportDashboardParametersDTO {
	report_id: ReportDTO['id'];
	allocation_id: InventoryAllocationDTO['id'];
}
export type GetReportDashboardBodyDTO = {};
export interface GetReportDashboardResponseDTO {
	type: string;
	id: string;
	token: string;
	dashboard_id: string;
}

/**
 * ---------- ENGINE ----------
 */

/**
 * ---------- GET /api/v1/engine/last_data_sync_stock_levels ----------
 */

export type GetLastStockSyncParametersDTO = {};
export type GetLastStockSyncBodyDTO = {};
export type GetLastStockSyncResponseDTO = DateString;

/**
 * ---------- GET /api/v1/engine/last_data_sync_forecasting ----------
 */

export type GetLastForecastingSyncParametersDTO = {};
export type GetLastForecastingSyncBodyDTO = {};
export type GetLastForecastingSyncResponseDTO = DateString;

/**
 * ---------- POST /api/v1/engine/ ----------
 */

export interface StartEngineParametersDTO {
	report_id: ReportDTO['id'];
	allocation_id: InventoryAllocationDTO['id'];
}
export type StartEngineBodyDTO = {};
export type StartEngineResponseDTO = {};

/**
 * ---------- GET /api/v1/engine/status ----------
 */

export interface GetEngineStatusParametersDTO {
	report_id: ReportDTO['id'];
}
export type GetEngineStatusBodyDTO = {};
export type GetEngineStatusResponseDTO = EngineStatusDTO;

/**
 * ---------- GET /api/v1/engine/retrieve_updated_status/:allocation_id/:report_id
 */

export interface GetReportWithLiveStatusParametersDTO {
	allocation_id: InventoryAllocationDTO['id'];
	report_id: ReportDTO['id'];
}
export type GetReportWithLiveStatusBodyDTO = {};
export type GetReportWithLiveStatusResponseDTO = ReportDTO;
