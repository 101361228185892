import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link as ScrollLink } from 'react-scroll';

const MarkerLink = ({ to, href, children }) => {
	if (!children) return null;
	if (!to && !href) return null;

	const className =
		'cursor-pointer bg-ca-purple bg-opacity-10 border-b border-ca-purple border-opacity-50 hover:bg-opacity-20 hover:border-opacity-100 transition-colors';

	if (href) {
		return (
			<a href={href} className={className} target="_blank" rel="noreferrer">
				{children}
			</a>
		);
	}

	if (to.startsWith('#')) {
		return (
			<ScrollLink
				to={to.replace('#', '')}
				className={className}
				smooth
				duration={500}
			>
				{children}
			</ScrollLink>
		);
	}

	return (
		<Link to={to} className={className} target="_blank" rel="noreferrer">
			{children}
		</Link>
	);
};

MarkerLink.propTypes = {
	to: PropTypes.string,
	href: PropTypes.string,
	children: PropTypes.node.isRequired,
};

MarkerLink.defaultProps = {
	to: '',
	href: '',
};

export default MarkerLink;
