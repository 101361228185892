import PropTypes from 'prop-types';

const Info = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18 20L18 25C18 26.1046 18.8954 27 20 27C21.1046 27 22 26.1046 22 25L22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20ZM20 12C18.8954 12 18 12.8954 18 14C18 15.1046 18.8954 16 20 16C21.1046 16 22 15.1046 22 14C22 12.8954 21.1046 12 20 12Z"
			fill="currentColor"
		/>
	</svg>
);

Info.propTypes = {
	className: PropTypes.string,
};

Info.defaultProps = {
	className: '',
};

export default Info;
