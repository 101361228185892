import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import clsx from 'clsx';

import { Auth0Provider } from '@auth0/auth0-react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ModalProvider } from './shared/context/ModalContext';
import { ChannelProvider } from './shared/context/ChannelContext';
import Sidebar, { SidebarLinkType } from './shared/components/Sidebar/Sidebar';
import ToastQueue from './shared/components/Toast/ToastQueue';
import JWTProvider from './shared/components/JWT/JWTProvider';

import LoginView from './login/views/Login';

import QueryClientProvider from './QueryClientProvider';

import BarChartIcon from './shared/components/Icons/BarChart';
import StrategyIcon from './shared/components/Icons/Strategy';
import ActualsIcon from './shared/components/Icons/Actual';
import ExperimentIcon from './shared/components/Icons/Experiment';
import DocsIcon from './shared/components/Icons/Docs';
import EnabledRoutes from './EnabledRoutes';

const STRATOS_SIDEBAR_ITEMS: SidebarLinkType[] = [
	{
		id: 'actuals',
		label: 'Actuals',
		to: '/actuals',
		icon: <ActualsIcon />,
		isEnabled: window._ENV_.REACT_APP_ACTUALS_ENABLED,
	},
	{
		id: 'strategy',
		label: 'Strategy',
		to: '/strategy',
		icon: <StrategyIcon />,
		isEnabled: window._ENV_.REACT_APP_STRATEGY_ENABLED,
	},
	{
		id: 'opportunities',
		label: 'Opportunities',
		to: '/opportunities',
		icon: <BarChartIcon />,
		isEnabled: window._ENV_.REACT_APP_OPPORTUNITIES_ENABLED,
	},
	{
		id: 'scenario',
		label: 'Scenario',
		to: '/scenario',
		icon: <ExperimentIcon />,
		isEnabled: window._ENV_.REACT_APP_SCENARIO_ENABLED,
	},
	{
		id: 'support',
		label: 'Support',
		to: '/support',
		icon: <DocsIcon />,
		isEnabled: window._ENV_.REACT_APP_SUPPORT_ENABLED,
	},
];

const VULCAN_SIDEBAR_ITEMS: SidebarLinkType[] = [
	{
		id: 'inventory-allocations',
		label: 'Inventory Allocations',
		to: '/stock/inventory-allocations',
		icon: <ExperimentIcon />,
		isEnabled: true,
	},
];

const App = () => {
	return (
		<HelmetProvider>
			<QueryClientProvider>
				{process.env.NODE_ENV === 'development' && (
					<ReactQueryDevtools position="bottom-right" />
				)}
				<ModalProvider>
					<BrowserRouter>
						<Auth0Provider
							domain={window._ENV_.REACT_APP_AUTH0_DOMAIN}
							clientId={window._ENV_.REACT_APP_AUTH0_CLIENT_ID}
							redirectUri={`${window.location.origin}/login`}
							scope="read:current_user"
							audience={`https://${window._ENV_.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
							organization={window._ENV_.REACT_APP_AUTH0_ORGANIZATION_ID}
							useRefreshTokens
							cacheLocation="localstorage"
						>
							<JWTProvider>
								<div
									className={clsx(
										'bg-ca-ghost-white min-h-screen',
										process.env.NODE_ENV === 'development'
											? // quickly identify which text is missing a color definition in DEV-mode
											  'text-red-600'
											: 'text-ca-black'
									)}
								>
									<Switch>
										<Route exact path="/login" component={LoginView} />
										<Route exact path="*">
											<Helmet title="Crunch Platform" />
											<Switch>
												<Route path="/stock">
													<Sidebar
														activePlatform="Stock"
														links={VULCAN_SIDEBAR_ITEMS}
													/>
												</Route>
												<Route
													path={[
														'/actuals',
														'/strategy',
														'/opportunities',
														'/scenario',
														'/support',
														'/page-not-found',
														'/',
													]}
												>
													<Sidebar
														activePlatform="Markdown"
														links={STRATOS_SIDEBAR_ITEMS}
													/>
												</Route>
											</Switch>
											<div className="pl-40">
												<ChannelProvider>
													<EnabledRoutes />
												</ChannelProvider>
											</div>
										</Route>
									</Switch>
								</div>
							</JWTProvider>
						</Auth0Provider>
					</BrowserRouter>
				</ModalProvider>
				<ToastQueue />
			</QueryClientProvider>
		</HelmetProvider>
	);
};

export default App;
