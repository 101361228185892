import Icon from './Icon';

const Docs: Icon = ({ className = '' }) => (
	<svg
		className={className}
		viewBox="0 0 20 20"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m8 18c0 1.1045695-.8954305 2-2 2h-4c-1.1045695 0-2-.8954305-2-2v-4c0-1.1045695.8954305-2 2-2h4v-4h-4c-1.1045695 0-2-.8954305-2-2v-4c0-1.1045695.8954305-2 2-2h4c1.1045695 0 2 .8954305 2 2v4h4v-4c0-1.05436179.8158778-1.91816512 1.8507377-1.99451426l.1492623-.00548574h4c1.1045695 0 2 .8954305 2 2v4c0 1.1045695-.8954305 2-2 2h-4v4h4c1.1045695 0 2 .8954305 2 2v4c0 1.1045695-.8954305 2-2 2h-4c-1.1045695 0-2-.8954305-2-2v-4h-4zm9.5-4h-3.5v3.5c0 .2761424.2238576.5.5.5h3c.2454599 0 .4496084-.1768752.4919443-.4101244l.0080557-.0898756v-3c0-.2454599-.1768752-.4496084-.4101244-.4919443zm-15 0-.08987563.0080557c-.23324921.0423359-.41012437.2464844-.41012437.4919443v3l.00805567.0898756c.04233596.2332492.24648444.4101244.49194433.4101244h3c.27614237 0 .5-.2238576.5-.5v-3.5zm9.5-6h-4v4h4zm-6.5-6h-3c-.24545989 0-.44960837.17687516-.49194433.41012437l-.00805567.08987563v3c0 .24545989.17687516.44960837.41012437.49194433l.08987563.00805567h3.5v-3.5c0-.27614237-.22385763-.5-.5-.5zm12 0h-3c-.2761424 0-.5.22385763-.5.5v3.5h3.5l.0898756-.00805567c.2332492-.04233596.4101244-.24648444.4101244-.49194433v-3l-.0080557-.08987563c-.0423359-.23324921-.2464844-.41012437-.4919443-.41012437z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export default Docs;
