import { useEffect, useRef } from 'react';
import { CumulioDashboardComponent } from '@cumul.io/react-cumulio-dashboard';
import useChannelQuery from '../../hooks/useChannelQuery';
import LinearProgress from '../Progress/LinearProgress';
import Button from '../Button/Button';
import RepeatIcon from '../Icons/Repeat';

const FullCumulioDashboard = ({ queryKey, ssoQuery, showLoading = true }) => {
	const {
		data,
		isFetching: isDataFetching,
		isFetchedAfterMount,
	} = useChannelQuery(queryKey, ssoQuery, {
		refetchOnMount: 'always',
		refetchOnReconnect: 'always',
	});
	const ref = useRef(null);

	useEffect(async () => {
		await ref?.current?.reloadDashboard();
	}, [data?.token]);

	return (
		<>
			{showLoading && (
				<div className="absolute left-32 right-0 top-0">
					<LinearProgress visible={isDataFetching} />
				</div>
			)}
			{data?.dashboard_id &&
				data?.id &&
				data?.token &&
				!isDataFetching &&
				isFetchedAfterMount && (
					<div className="relative mt-4">
						<div className="relative overflow-hidden z-0">
							<div className="flex flex-row justify-end">
								<Button
									className="inline-flex items-center"
									variant="primary"
									size="small"
									onClick={async () =>
										console.log(await ref?.current?.reloadDashboard())
									}
								>
									Reload dashboard <RepeatIcon className="h-3.5 ml-2" />
								</Button>
							</div>
							<CumulioDashboardComponent
								dashboardId={data?.dashboard_id}
								ref={ref}
								authKey={data?.id}
								authToken={data?.token}
								loaderBackground="#F5F5F7"
								loaderFontColor="transparent"
								loaderSpinnerColor="#6111C7"
								loaderSpinnerBackground="transparent"
								loaderLogoColor="transparent"
								itemsRendered={(e) => console.log('itemsRendered', e)}
								load={(e) => console.log('loaded', e)}
							/>
						</div>
					</div>
				)}
		</>
	);
};

export default FullCumulioDashboard;
