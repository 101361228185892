import { useCallback, useMemo, useState, Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useMutation, useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import clsx from 'clsx';
import download from 'downloadjs';
import mime from 'mime/lite';

import {
	GET_OPPORTUNITIES,
	GET_OPPORTUNITIES_SCHEMA,
	UPDATE_OPPORTUNITY,
	GET_OPPORTUNITIES_EXPORT,
} from '../../shared/api/opportunities';
import {
	GET_PRODUCT,
	GET_PRODUCT_MARKDOWN_OPPORTUNITIES,
} from '../../shared/api/products';
import { GET_CUMULIO_OPPORTUNITIES_PRODUCT_SSO } from '../../shared/api/reports';

import { useOpportunitiesHeadings as useHeadings } from '../../shared/data/headings';

import useChannelQuery from '../../shared/hooks/useChannelQuery';
import useChannelPrefetch from '../../shared/hooks/useChannelPrefetch';
import useDebounce from '../../shared/hooks/useDebounce';

import Text from '../../shared/components/Text/Text';
import TextInput from '../../shared/components/TextInput/TextInput';
import Button from '../../shared/components/Button/Button';
import Table from '../../shared/components/Table/Table';
import RadioGroup from '../../shared/components/Radio/RadioGroup';
import Dropdown from '../../shared/components/Dropdown/Dropdown';
import LinearProgress from '../../shared/components/Progress/LinearProgress';
import CircularProgress from '../../shared/components/Progress/CircularProgress';
import TableHeaderButton from '../../shared/components/TableHeaderButton/TableHeaderButton';
import AlgorithmStatus from '../../shared/components/AlgorithmStatus/AlgorithmStatus';

import OpportunitiesFilter from '../components/OpportunitiesFilter';
import OpportunitiesDetail from '../components/OpportunitiesDetail';
import Tooltip from '../../shared/components/Tooltip/Tooltip';

const OpportunitiesDetailsView = () => {
	const [pagination, setPagination] = useState({ page: 1, size: 50 });
	const [sort, setSort] = useState({
		key: 'attainable_increase_objective',
		direction: 'desc',
	});
	const [filters, setFilters] = useState(new Map());
	const [selected, setSelected] = useState([]);
	const [open, setOpen] = useState([]);
	const [selectAll, setSelectAll] = useState(null); // `null` = should show popup, true = all selected, false = only current selection
	const [search, setSearch] = useState('');
	const {
		disabledHeadings,
		toggleHeading,
		addToDefaultDisabledHeading,
		resetHeadings,
	} = useHeadings();
	const debouncedSearch = useDebounce(
		search,
		500,
		(v) => v === '' || v.length >= 3
	);
	const prefetchQuery = useChannelPrefetch();
	const queryClient = useQueryClient();

	const { isLoading: isSchemaLoading, data: schema = [] } = useChannelQuery(
		'opportunities-schema',
		GET_OPPORTUNITIES_SCHEMA,
		{
			staleTime: 5 * 60 * 1000,
			onSuccess: (data) => {
				data
					.filter((col) => col.hidden_by_default)
					.forEach((col) => addToDefaultDisabledHeading(col.id));
				resetHeadings();
			},
		}
	);

	// convert id to bq_name for sorting filter
	const getSortParams = () => {
		const heading = schema.find(({ id }) => id === sort.key);

		if (!heading) {
			return null;
		}

		return ['sort', `${heading.id}:${sort.direction}`];
	};

	const {
		isFetching: isDataFetching,
		data,
		channel,
	} = useChannelQuery(
		[
			'opportunities',
			pagination,
			Object.fromEntries(filters),
			sort,
			debouncedSearch,
		],
		() => {
			const sortParams = getSortParams();

			return GET_OPPORTUNITIES(
				new URLSearchParams([
					['page', pagination.page],
					['size', pagination.size],
					['search_filter', debouncedSearch],
					...(sortParams ? [sortParams] : []),
					...Array.from(filters.entries()).map(([id, value]) => [
						'filter',
						`"id":"${id}","value":${JSON.stringify(value)}`,
					]),
				])
			);
		},
		{
			staleTime: 5 * 60 * 1000,
			keepPreviousData: true,
		}
	);

	useEffect(() => {
		// automatically reset select-all pop-up state when
		// selecting less than a full page
		if (selected?.length < pagination?.size) {
			setSelectAll(null);
		}
	}, [selected]);

	const headings = useMemo(
		() =>
			schema.map((s) => ({
				id: s.id,
				label: s.name,
				tooltip: s.tooltip,
				sortable: s.sortable,
				enabled: !disabledHeadings.includes(s.id),
				align: 'center',
				...([
					'attainable_increase_turnover',
					'attainable_increase_margin',
					'attainable_increase_objective',
				].includes(s.id)
					? { maxWidth: '100px' }
					: {}),
			})),
		[schema, disabledHeadings]
	);

	const handleFiltersChange = (newFilters) => {
		setFilters(newFilters);
		// go back to page 1
		setPagination((p) => ({ ...p, page: 1 }));
		// clear the current selection
		setSelected([]);
		// clear the open rows
		setOpen([]);
	};

	const handleSortChange = (s) => {
		setSort(s);
		// go back to page 1
		setPagination((p) => ({ ...p, page: 1 }));
		// clear the current selection
		setSelected([]);
		// clear the open rows
		setOpen([]);
	};

	const handlePaginationChange = (pag) => {
		setPagination(pag);
		// clear the open rows
		setOpen([]);
	};

	const { mutate: overwriteOpportunity } = useMutation(UPDATE_OPPORTUNITY, {
		onMutate: async (opp) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries([channel, 'opportunities']);

			// override opportunities to have a loading state set to `true` for the overwritten opp
			queryClient.setQueriesData([channel, 'opportunities'], (old) => ({
				...old,
				items: (old?.items || []).map((o) =>
					o?.product_id === opp?.product_id
						? { ...o, ...opp, loading: true }
						: o
				),
			}));
		},
		onSettled: (d, _err, opp) => {
			// override opportunities with the new date for the overwritten opp and cancel the loading state
			queryClient.setQueriesData([channel, 'opportunities'], (old) => ({
				...old,
				items: (old?.items || []).map((o) =>
					o?.product_id === opp?.product_id ? d : o
				),
			}));
		},
	});

	const { isLoading: isExportLoading, mutate: exportOpportunities } =
		useMutation(
			() =>
				GET_OPPORTUNITIES_EXPORT(
					selectAll,
					new URLSearchParams([
						// add specific product_ids if not export-all
						...(selectAll ? [] : [['product_ids', selected.join(',')]]),
						// add filters if export-all
						...(selectAll
							? [
									...Array.from(filters.entries()).map(([id, value]) => [
										'filter',
										`"id":"${id}","value":${JSON.stringify(value)}`,
									]),
							  ]
							: []),
					])
				),
			{
				onSuccess: (blob) => {
					download(blob, `export.${mime.getExtension(blob.type)}`, blob.type);
				},
			}
		);

	const handleRowOverwrite = (opp) => {
		overwriteOpportunity({
			product_id: opp?.product_id,
			markdown_type: opp?.markdown_type,
			overwrite_markdown: opp?.overwrite_markdown,
		});
	};

	const handleIdClicked = useCallback(
		(id) => {
			if (open.includes(id)) {
				setOpen((o) => o.filter((oId) => oId !== id));
			} else {
				setOpen((o) => [...o, id]);
			}
		},
		[open]
	);

	const prefetchRow = (oId) => {
		prefetchQuery(['product', oId], () => GET_PRODUCT(oId), {
			staleTime: 5 * 60 * 1000,
		});
		prefetchQuery(
			['product-markdown-opportunities', oId],
			() => GET_PRODUCT_MARKDOWN_OPPORTUNITIES(oId),
			{ staleTime: 5 * 60 * 1000 }
		);
		prefetchQuery(['cumulio-opportunities-product', oId], () =>
			GET_CUMULIO_OPPORTUNITIES_PRODUCT_SSO(oId)
		);
	};

	const renderOpen = useCallback(
		(oId) => {
			const opportunity = data?.items?.find((o) => o?.product_id === oId);

			if (!opportunity) return null;

			return (
				<OpportunitiesDetail
					id={opportunity?.product_id}
					onClose={() => setOpen((o) => o.filter((id) => id !== oId))}
				/>
			);
		},
		[data]
	);

	const renderCell = useCallback(
		(row, columnId) => {
			const { field_type: fieldType, allowed_markdowns: allowedMarkdowns } =
				schema.find(({ id }) => id === columnId);

			if (fieldType === 'date') {
				return dayjs(row?.[columnId]).format('DD/MM/YYYY');
			}

			if (fieldType === 'image') {
				return row?.[columnId] ? (
					<span
						onMouseOver={() => prefetchRow(row?.product_id)}
						onFocus={() => {}}
					>
						<Button
							variant="unstyled"
							onClick={() => handleIdClicked(row?.product_id)}
						>
							<img className="inline-block w-16" src={row?.[columnId]} alt="" />
						</Button>
					</span>
				) : null;
			}

			if (fieldType === 'custom_markdown') {
				return (
					<div className="h-full flex justify-center text-left relative">
						<RadioGroup
							options={[
								{ value: 'Current', label: 'Current' },
								{ value: 'Optimal', label: 'Optimal' },
								{ value: 'Custom', label: 'Custom' },
							]}
							value={row?.markdown_type}
							onChange={(type) =>
								handleRowOverwrite({
									...row,
									markdown_type: type,
									overwrite_markdown:
										row?.overwrite_markdown || allowedMarkdowns[0],
								})
							}
							className={{
								root: 'space-y-3',
								radio: 'text-xs',
								active: 'text-xs font-bold',
							}}
						/>
						<div className="h-full ml-4 flex flex-col align-baseline">
							<Text size="text-xs" className="leading-none py-0.5">
								{Math.round(row?.current_markdown * 100)}%
							</Text>
							<Text size="text-xs" className="leading-none mt-3 mb-2 py-0.5">
								{Math.round(row?.optimal_markdown * 100)}%
							</Text>
							<Dropdown
								onChange={(val) =>
									handleRowOverwrite({
										...row,
										markdown_type: 'Custom',
										overwrite_markdown: val,
									})
								}
								value={row?.overwrite_markdown || allowedMarkdowns[0]}
								options={(allowedMarkdowns || []).map((n) => ({
									value: n,
									label: `${Math.round(n * 100)}%`,
								}))}
								size="small"
								className="-mt-px"
							/>
						</div>
					</div>
				);
			}

			if (
				fieldType === 'ati_turnover' ||
				fieldType === 'ati_margin' ||
				fieldType === 'ati_objective'
			) {
				const optimal = row?.[`${columnId}_optimal`];
				const overwrite =
					row?.[`${columnId}_overwrite`] ||
					`${optimal?.replace('-', '').charAt(0)}0`; // get currency from optimal value + add 0 when no overwrite value was present

				if (row?.loading) {
					return (
						<div className="flex justify-center">
							<CircularProgress size="small" />
						</div>
					);
				}

				return (
					<>
						{optimal && (
							<Text className="font-bold">
								{optimal.toString().startsWith('-') && '- '}
								<span
									className={clsx(
										row?.markdown_type !== 'Optimal' &&
											'line-through decoration-2'
									)}
								>
									{optimal.toString().replace('-', '')}
								</span>
							</Text>
						)}
						{row?.markdown_type !== 'Optimal' && (
							<Text className="mt-2 font-bold">
								{overwrite.toString().replace('-', '- ')}
							</Text>
						)}
					</>
				);
			}
			if (
				fieldType === 'ati_turnover_percentage' ||
				fieldType === 'ati_margin_percentage' ||
				fieldType === 'ati_objective_percentage'
			) {
				const optimal = row?.[`${columnId}_optimal`];
				const overwrite = row?.[`${columnId}_overwrite`] || `%0`; // get currency from optimal value + add 0 when no overwrite value was present

				if (row?.loading) {
					return (
						<div className="flex justify-center">
							<CircularProgress size="small" />
						</div>
					);
				}

				return (
					<>
						{optimal && (
							<Text className="font-bold">
								{optimal.toString().startsWith('-') && '- '}
								<span
									className={clsx(
										row?.markdown_type !== 'Optimal' &&
											'line-through decoration-2'
									)}
								>
									{optimal.toString().replace('-', '')}
								</span>
							</Text>
						)}
						{row?.markdown_type !== 'Optimal' && (
							<Text className="mt-2 font-bold">
								{overwrite.toString().replace('-', '- ')}
							</Text>
						)}
					</>
				);
			}

			if (fieldType === 'id') {
				return (
					<span
						onMouseOver={() => prefetchRow(row?.[columnId])}
						onFocus={() => {}}
					>
						<Button
							variant="unstyled"
							className="hover:underline"
							onClick={() => handleIdClicked(row?.[columnId])}
						>
							{row?.[columnId]?.toString()}
						</Button>
					</span>
				);
			}

			return row?.[columnId]?.toString();
		},
		[data, open]
	);

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress
					visible={isDataFetching || isSchemaLoading || isExportLoading}
				/>
			</div>
			<div className="relative pt-6">
				<div className="absolute right-0 -top-5">
					<AlgorithmStatus strategyId="default" />
				</div>
				<div className="flex justify-between" style={{ minHeight: '38px' }}>
					<div className="grow self-center">
						<OpportunitiesFilter
							filters={filters}
							onChange={handleFiltersChange}
						/>
					</div>
					<div className="flex items-center">
						<Text className="font-bold mx-4">
							{selectAll ? data?.total : selected.length} selected
						</Text>
						<Tooltip
							content={
								!selected.length && <>Select some products to export them.</>
							}
						>
							<span>
								<Button
									disabled={!selected.length}
									onClick={exportOpportunities}
								>
									Export
								</Button>
							</span>
						</Tooltip>
						<div className="h-full w-0.5 bg-ca-silver mx-6" />

						<TextInput
							id="search"
							placeholder="Search product code"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>
						<div className="ml-3">
							<TableHeaderButton
								headings={headings}
								disabled={false}
								loading={isSchemaLoading}
								onChange={toggleHeading}
								onReset={resetHeadings}
							/>
						</div>
					</div>
				</div>
				<Transition
					show={selected.length === pagination?.size && selectAll === null}
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<div className="absolute z-10 left-0 w-96 p-5 mt-9 ml-12 bg-ca-black bg-opacity-90 rounded">
						<Text size="text-xs" type="inverted">
							All {pagination?.size} opportunities on this page have been
							selected.
						</Text>
						<Text size="text-xs" type="inverted" className="mt-2">
							Would you like to select{' '}
							<strong>all {data?.total} opportunities</strong> instead?
						</Text>
						<div className="flex justify-between items-center mt-4">
							<Button
								size="small"
								variant="secondary"
								onClick={() => setSelectAll(false)}
							>
								Keep current selection
							</Button>
							<Button size="small" onClick={() => setSelectAll(true)}>
								Select all opportunities
							</Button>
						</div>
					</div>
				</Transition>
				<div className="mt-6">
					<Table
						headings={headings.filter(({ enabled }) => enabled)}
						rows={data?.items}
						rowKey="product_id"
						renderCell={renderCell}
						pagination={{
							currentPage: pagination?.page,
							items: data?.total,
							itemsPerPage: pagination?.size,
							itemsPerPageOptions: [10, 25, 50, 100],
						}}
						onPageChange={(page) =>
							handlePaginationChange((p) => ({ ...p, page }))
						}
						onItemsPerPageChange={(size) =>
							handlePaginationChange({ size, page: 1 })
						}
						sort={sort}
						onSortChange={handleSortChange}
						loading={isDataFetching}
						itemsLoading={pagination?.size}
						emptyState="No opportunities found for the selected filters..."
						selectable
						selected={selected}
						onSelectedChange={setSelected}
						open={open}
						renderOpen={renderOpen}
					/>
				</div>
			</div>
		</>
	);
};

export default OpportunitiesDetailsView;
