import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const TextInput = ({
	id,
	placeholder,
	type,
	size,
	startAdornment,
	endAdornment,
	error,
	className,
	...props
}) => {
	const [rootElement, setRootElement] = useState(null);
	const [inputElement, setInputElement] = useState(null);
	const [isValidDate, setIsValidDate] = useState(false);

	useEffect(() => {
		if (type === 'date') {
			// eslint-disable-next-line react/prop-types
			setIsValidDate(!!props?.value);
		}

		// eslint-disable-next-line react/prop-types
	}, [type, props?.value]);

	useEffect(() => {
		const onFocus = () => {
			if (inputElement) {
				inputElement.focus();
			}
		};

		if (rootElement) {
			rootElement.addEventListener('focus', onFocus);
		}

		return () =>
			rootElement && rootElement.removeEventListener('focus', onFocus);
	}, [rootElement, inputElement]);

	return (
		<div className={clsx('relative inline-flex flex-wrap', className)}>
			<div
				ref={setRootElement}
				tabIndex="0"
				role="textbox"
				className={clsx(
					'relative w-full overflow-hidden border rounded-lg transition-colors focus:outline-none focus-within:ring-4 focus-within:ring-opacity-10',
					!error &&
						'text-ca-gray border-ca-silver focus-within:border-ca-purple focus-within:ring-ca-purple focus-within:text-ca-black',
					error && 'text-ca-red border-ca-red focus-within:ring-ca-red',
					size === 'regular' && 'h-10',
					size === 'small' && 'h-6'
				)}
			>
				{startAdornment && (
					<span
						className={clsx(
							'absolute left-0 -top-px bg-ca-silver rounded-lg rounded-r-none text-center',
							size === 'regular' && 'px-4 text-sm',
							size === 'small' && 'px-2 text-xs',
							error && 'bg-ca-red text-white'
						)}
						style={{
							width: size === 'regular' ? '42px' : '26px',
							height: size === 'regular' ? '42px' : '26px',
							lineHeight: size === 'regular' ? '42px' : '26px',
						}}
					>
						{startAdornment}
					</span>
				)}
				<input
					ref={setInputElement}
					className={clsx(
						'w-full h-full block placeholder-ca-gray bg-white leading-none focus:outline-none',
						{ 'text-ca-black': !error && (type !== 'date' || isValidDate) },
						{ 'text-ca-gray': !error && type === 'date' && !isValidDate },
						{ 'text-ca-red': error },
						size === 'regular' && 'px-4 py-3 text-sm',
						size === 'small' && 'px-2 text-xs'
					)}
					style={{
						marginLeft: clsx({
							'42px': startAdornment && size === 'regular',
							'26px': startAdornment && size === 'small',
						}),
						marginRight: clsx({
							'42px': endAdornment && size === 'regular',
							'26px': endAdornment && size === 'small',
						}),
					}}
					id={id}
					name={id}
					placeholder={placeholder}
					type={type}
					{...props}
					onChange={(e) => {
						if (type === 'date') {
							setIsValidDate(!!e.target.value);
						}
						props.onChange(e);
					}}
				/>
				{endAdornment && (
					<span
						className={clsx(
							'absolute right-0 -top-px bg-ca-silver rounded-lg rounded-l-none text-center',
							size === 'regular' && 'px-4 text-sm',
							size === 'small' && 'px-2 text-xs',
							error && 'bg-ca-red text-white'
						)}
						style={{
							width: size === 'regular' ? '42px' : '26px',
							height: size === 'regular' ? '42px' : '26px',
							lineHeight: size === 'regular' ? '42px' : '26px',
						}}
					>
						{endAdornment}
					</span>
				)}
			</div>
			{error && typeof error === 'string' && (
				<span className="w-full mt-1 text-ca-red text-xs text-right">
					{error}
				</span>
			)}
		</div>
	);
};

TextInput.propTypes = {
	id: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	type: PropTypes.oneOf(['text', 'password', 'date', 'number']),
	size: PropTypes.oneOf(['regular', 'small']),
	startAdornment: PropTypes.string,
	endAdornment: PropTypes.string,
	error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	className: PropTypes.string,
	onChange: PropTypes.func,
};

TextInput.defaultProps = {
	placeholder: '',
	type: 'text',
	size: 'regular',
	startAdornment: '',
	endAdornment: '',
	error: false,
	className: '',
	onChange: () => {},
};

export default TextInput;
