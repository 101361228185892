import { ProductDTO, StockLocationDTO } from './schema';

export enum PrimaryObjective {
	Revenue = 'revenue',
}

export enum ConstraintType {
	MaximumVolumeMoved = 'maximumVolumeMoved',
	MinimumShipmentAmount = 'minimumShipmentAmount',
	MaximumShipmentAmount = 'maximumShipmentAmount',
	DoNotBreakSizeChart = 'doNotBreakSizeChart',
	CooldownPeriod = 'cooldownPeriod',
	MinimumROI = 'minimumROI',
	Capacity = 'capacity',
	OnlyRestoreSizeCharts = 'onlyRestoreSizeCharts',
	Unknown = 'unknown',
}

export enum SecondaryObjectiveType {
	FixBrokenSizeCharts = 'Size charts will be made whole.',
	PenalizeBrokenSizeCharts = 'Penalize broken size charts in order to promote collecting extreme sizes.',
	Unknown = 'unknown',
}

export interface Scope {
	products: ProductDTO['product_id'][];
	locations: StockLocationDTO['stock_location_id'][];
}

export interface BaseConstraint {
	type: ConstraintType;
	scope: Scope;
	estimatedMissedRevenue?: number;
}

export interface MaximumVolumeMovedConstraint extends BaseConstraint {
	type: ConstraintType.MaximumVolumeMoved;
	maxMovementCount: number;
}

export interface MinimumShipmentAmountConstraint extends BaseConstraint {
	type: ConstraintType.MinimumShipmentAmount;
	minimumShipmentAmount: number;
}

export interface MaximumShipmentAmountConstraint extends BaseConstraint {
	type: ConstraintType.MaximumShipmentAmount;
	maximumShipmentAmount: number;
}

export interface DoNotBreakSizeChartConstraint extends BaseConstraint {
	type: ConstraintType.DoNotBreakSizeChart;
}

export interface CooldownPeriodConstraint extends BaseConstraint {
	type: ConstraintType.CooldownPeriod;
	minCooldownDays: number;
}

export interface MinimumROIConstraint extends BaseConstraint {
	type: ConstraintType.MinimumROI;
	minimumROI: number;
}

export interface CapacityConstraint extends BaseConstraint {
	type: ConstraintType.Capacity;
}

export interface OnlyRestoreSizeChartsConstraint extends BaseConstraint {
	type: ConstraintType.OnlyRestoreSizeCharts;
}

export type Constraint =
	| MaximumVolumeMovedConstraint
	| MinimumShipmentAmountConstraint
	| MaximumShipmentAmountConstraint
	| DoNotBreakSizeChartConstraint
	| CooldownPeriodConstraint
	| MinimumROIConstraint
	| CapacityConstraint
	| OnlyRestoreSizeChartsConstraint;

export interface BaseSecondaryObjective {
	type: SecondaryObjectiveType;
	scope: Scope;
	estimatedMissedRevenue?: number;
	weight: number;
}

export interface FixBrokenSizeChartsSecondaryObjective
	extends BaseSecondaryObjective {
	type: SecondaryObjectiveType.FixBrokenSizeCharts;
}

export interface PenalizeBrokenSizeChartsSecondaryObjective
	extends BaseSecondaryObjective {
	type: SecondaryObjectiveType.PenalizeBrokenSizeCharts;
}

export type SecondaryObjective =
	| FixBrokenSizeChartsSecondaryObjective
	| PenalizeBrokenSizeChartsSecondaryObjective;

export interface MarketingExpectation {
	campaignName: string;
	scope: Scope;
	salesModifier: number;
}

export interface Configuration {
	primaryObjective: PrimaryObjective;
	scope: Scope;
	constraints: Constraint[];
	secondaryObjectives: SecondaryObjective[];
	marketingExpectations: MarketingExpectation[];
}
