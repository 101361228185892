import api from './api';

export const GET_OPPORTUNITIES_FILTERS = () => {
	return api.get('api/v2/opportunities/filters').json();
};

export const GET_OPPORTUNITIES_SCHEMA = () => {
	return api.get('api/v2/opportunities/schema').json();
};

export const GET_OPPORTUNITIES = (searchParams) => {
	return api
		.get('api/v2/opportunities', {
			searchParams,
		})
		.json();
};

export const UPDATE_OPPORTUNITY = (payload) => {
	return api
		.post('api/v2/opportunities/overwrite', {
			json: payload,
		})
		.json();
};

export const GET_OPPORTUNITIES_EXPORT = (all, searchParams) => {
	return api
		.get(`api/v2/opportunities/export${all ? '/all' : ''}`, {
			searchParams,
		})
		.blob();
};
