import { useState, useEffect } from 'react';
import DocNavLink from './DocNavLink';

const NAVIGATION_ITEMS = [
	{
		id: 'overview',
		title: 'Overview',
	},
	{
		id: 'strategy',
		title: 'Strategy',
		items: [
			{
				id: 'strategy-overview',
				title: 'Overview',
				items: [
					{
						id: 'strategy-overview-end-of-markdown-period',
						title: 'End of markdown period',
					},
					{
						id: 'strategy-overview-optimization-objective',
						title: 'Optimization objective',
					},
				],
			},
			{
				id: 'strategy-product-categories',
				title: 'Product categories',
			},
			{
				id: 'strategy-business-rules',
				title: 'Business rules',
				items: [
					{ id: 'strategy-business-rules-if', title: 'If' },
					{ id: 'strategy-business-rules-then', title: 'Then' },
					{
						id: 'strategy-business-rules-conflicting-business-rules',
						title: 'Conflicting business rules',
					},
					{
						id: 'strategy-business-rules-enabling-business-rules',
						title: 'Enabling business rules',
					},
				],
			},
		],
	},
	{
		id: 'opportunities',
		title: 'Opportunities',
		items: [
			{
				id: 'opportunities-detail-page',
				title: 'Detail page',
				items: [
					{
						id: 'opportunities-detail-page-attainable-increase',
						title: 'Attainable increase',
					},
					{
						id: 'opportunities-detail-page-exporting-markdown-recommendations',
						title: 'Exporting markdown recommendations',
					},
				],
			},
		],
	},
	{
		id: 'scenario',
		title: 'Scenario',
		items: [
			{
				id: 'scenario-test-strategy',
				title: 'Test strategy',
				items: [
					{
						id: 'scenario-test-strategy-setting-up-a-test-strategy',
						title: 'Setting up a test strategy',
					},
				],
			},
		],
	},
];

const LEVEL_1 = NAVIGATION_ITEMS.map(({ id }) => id);
const LEVEL_2 = NAVIGATION_ITEMS.map(({ items = [] }) =>
	items.map(({ id }) => id)
).flat();
const LEVEL_3 = NAVIGATION_ITEMS.map(({ items = [] }) =>
	items.map(({ items: items2 = [] }) => items2.map(({ id }) => id)).flat()
).flat();

const DocNav = () => {
	const [itemStatus, setItemStatus] = useState({});

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				setItemStatus((aItems) => ({
					...aItems,
					[entry.target.id]: entry.isIntersecting,
				}));
			});
		});

		const elements = [...LEVEL_1, ...LEVEL_2, ...LEVEL_3].map((id) =>
			document.querySelector(`#${id}`)
		);

		elements.forEach((el) => {
			if (el) {
				observer.observe(el);
			}
		});

		return () => {
			elements.forEach((el) => {
				observer.unobserve(el);
			});
		};
	}, []);

	const activeLevels = Object.entries(itemStatus)
		.filter(([, status]) => !!status)
		.map(([id]) => id);

	// search levels separately so only 1 item (the first matching item)
	// gets activated at any given time
	// we also check if the active item is under the previous level in the hierarchy
	const activeL1 = LEVEL_1.find((l1) => activeLevels.includes(l1));
	const activeL2 = LEVEL_2.find((l2) => activeLevels.includes(l2));
	const activeL3 = LEVEL_3.find((l3) => activeLevels.includes(l3));

	const activePrimary = activeL1;
	const activeSecondary = activeL2?.startsWith(activePrimary) ? activeL2 : null;
	const activeTertiary = activeL3?.startsWith(activeSecondary)
		? activeL3
		: null;

	return (
		<div className="flex flex-col h-full bg-white p-6 border-l border-ca-black border-opacity-5 z-10 text-ca-gray text-sm">
			<ul className="space-y-6 overflow-auto">
				{NAVIGATION_ITEMS.map((primary) => (
					<div key={primary.id} className="space-y-2">
						<DocNavLink
							className="block font-bold cursor-pointer py-3 px-4 rounded-lg transition-colors"
							activeClassName="text-ca-purple bg-ca-purple bg-opacity-10"
							inactiveClassName="bg-ca-silver hover:text-ca-black"
							to={primary.id}
							smooth
							duration={500}
							active={primary.id === activePrimary}
						>
							{primary.title}
						</DocNavLink>
						{primary?.items?.length > 0 && (
							<ul className="space-y-2 ml-4 py-2">
								{primary?.items?.map((secondary) => (
									<li key={secondary.id} className="space-y-2">
										<DocNavLink
											className="cursor-pointer transition-colors"
											activeClassName="font-bold text-ca-purple"
											inactiveClassName="hover:text-ca-black"
											to={secondary.id}
											smooth
											duration={500}
											active={secondary.id === activeSecondary}
										>
											{secondary.title}
										</DocNavLink>
										{secondary?.items?.length > 0 && (
											<ul className="space-y-2 ml-4">
												{secondary?.items?.map((tertiary) => (
													<li key={tertiary.id}>
														<DocNavLink
															className="flex items-center cursor-pointer transition-colors"
															activeClassName="text-ca-purple"
															inactiveClassName="hover:text-ca-black"
															to={tertiary.id}
															smooth
															duration={500}
															active={tertiary.id === activeTertiary}
														>
															<svg
																width="3"
																height="24"
																viewBox="0 -9 3 24"
																className="inline-block mr-2 overflow-visible"
															>
																<path
																	d="M0 0L3 3L0 6"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																/>
															</svg>
															<span>{tertiary.title}</span>
														</DocNavLink>
													</li>
												))}
											</ul>
										)}
									</li>
								))}
							</ul>
						)}
					</div>
				))}
			</ul>
		</div>
	);
};

export default DocNav;
