import { Redirect, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import PrivateRoute from './shared/components/PrivateRoute/PrivateRoute';
import InventoryAllocationView from './vulcan/inventoryAllocation/views/InventoryAllocationView';
import InventoryAllocationsView from './vulcan/inventoryAllocations/views/InventoryAllocations';
import ActualsView from './actuals/views/Actuals';
import StrategyView from './strategy/views/Strategy';
import OpportunitiesView from './opportunities/views/Opportunities';
import ScenarioView from './scenario/views/Scenario';
import SupportView from './support/views/Support';
import NotFoundView from './shared/views/NotFound';
import RouterSwitch from './shared/components/RouterSwitch/RouterSwitch';
import useChannelStore from './shared/data/channels';

const EnabledRoutes = () => {
	const { activeChannel } = useChannelStore();
	const isEnabledCheck = (isEnabled: boolean | [string]): boolean => {
		if (typeof isEnabled === 'boolean') {
			return isEnabled;
		}
		return isEnabled.includes(activeChannel);
	};

	const [enabledRoutes, setEnabledRoutes] = useState<{ [id: string]: boolean }>(
		{
			'/actuals': isEnabledCheck(window._ENV_.REACT_APP_ACTUALS_ENABLED),
			'/strategy': isEnabledCheck(window._ENV_.REACT_APP_STRATEGY_ENABLED),
			'/opportunities': isEnabledCheck(
				window._ENV_.REACT_APP_OPPORTUNITIES_ENABLED
			),
			'/support': isEnabledCheck(window._ENV_.REACT_APP_SUPPORT_ENABLED),
			'/scenario': isEnabledCheck(window._ENV_.REACT_APP_SCENARIO_ENABLED),
		}
	);

	useEffect(() => {
		setEnabledRoutes({
			'/actuals': isEnabledCheck(window._ENV_.REACT_APP_ACTUALS_ENABLED),
			'/strategy': isEnabledCheck(window._ENV_.REACT_APP_STRATEGY_ENABLED),
			'/opportunities': isEnabledCheck(
				window._ENV_.REACT_APP_OPPORTUNITIES_ENABLED
			),
			'/support': isEnabledCheck(window._ENV_.REACT_APP_SUPPORT_ENABLED),
			'/scenario': isEnabledCheck(window._ENV_.REACT_APP_SCENARIO_ENABLED),
		});
	}, [activeChannel]);

	return (
		<RouterSwitch>
			<Redirect
				exact
				from="/"
				to={
					window._ENV_.REACT_APP_VULCAN_ENABLED
						? '/stock'
						: window._ENV_.REACT_APP_DEFAULT_PATH
				}
			/>
			<Redirect exact from="/stock" to="/stock/inventory-allocations" />
			{window._ENV_.REACT_APP_VULCAN_ENABLED && (
				<PrivateRoute path="/stock/inventory-allocations/:id">
					<Helmet title="Crunch Platform | Vulcan" />
					<InventoryAllocationView />
				</PrivateRoute>
			)}
			{window._ENV_.REACT_APP_VULCAN_ENABLED && (
				<PrivateRoute path="/stock/inventory-allocations">
					<Helmet title="Crunch Platform | Vulcan" />
					<InventoryAllocationsView />
				</PrivateRoute>
			)}
			{enabledRoutes['/actuals'] && (
				<PrivateRoute path="/actuals">
					<Helmet title="Crunch Platform | Actuals" />
					<ActualsView />
				</PrivateRoute>
			)}
			{enabledRoutes['/strategy'] && (
				<PrivateRoute path="/strategy">
					<Helmet title="Crunch Platform | Strategy" />
					<StrategyView />
				</PrivateRoute>
			)}
			{enabledRoutes['/opportunities'] && (
				<PrivateRoute path="/opportunities">
					<Helmet title="Crunch Platform | Opportunities" />
					<OpportunitiesView />
				</PrivateRoute>
			)}
			{enabledRoutes['/scenario'] && (
				<PrivateRoute path="/scenario">
					<Helmet title="Crunch Platform | Scenario" />
					<ScenarioView />
				</PrivateRoute>
			)}
			{enabledRoutes['/support'] && (
				<PrivateRoute path="/support">
					<Helmet title="Crunch Platform | Support" />
					<SupportView />
				</PrivateRoute>
			)}
			<Route path="/" exact>
				<Redirect to={window._ENV_.REACT_APP_DEFAULT_PATH} />
			</Route>
			<Route path="/page-not-found">
				<Helmet title="Crunch Platform | Page not found" />
				<NotFoundView />
			</Route>
		</RouterSwitch>
	);
};
export default EnabledRoutes;
