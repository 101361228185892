import { GET_CUMULIO_OPPORTUNITIES_GLOBAL_SUMMARY_SSO } from '../../shared/api/reports';
import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';

const OpportunitiesGlobalSummaryView = () => {
	return (
		<FullCumulioDashboard
			queryKey="cumulio-opportunities-global-summary"
			ssoQuery={GET_CUMULIO_OPPORTUNITIES_GLOBAL_SUMMARY_SSO}
		/>
	);
};

export default OpportunitiesGlobalSummaryView;
