import PropTypes from 'prop-types';

const Note = ({ title, children }) => {
	if (!children) return null;

	return (
		<blockquote className="px-4 py-6 bg-ca-purple bg-opacity-10 border-l-8 border-ca-purple text-ca-black rounded-lg">
			{title && <p className="mb-4 text-md font-bold">{title}</p>}
			{children}
		</blockquote>
	);
};

Note.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node.isRequired,
};

Note.defaultProps = {
	title: '',
};

export default Note;
