import { Route, Redirect } from 'react-router-dom';

import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import Page from '../../shared/components/Page/Page';
import OpportunitiesHeader from '../components/OpportunitiesHeader';
import OpportunitiesSummary from './OpportunitiesSummary';
import OpportunitiesGlobalRetrospective from './OpportunitiesGlobalRetrospective';
import OpportunitiesDetails from './OpportunitiesDetails';
import OpportunitiesGlobalSummary from './OpportunitiesGlobalSummary';

const OpportunitiesView = () => (
	<Page>
		<OpportunitiesHeader />
		<RouterSwitch>
			<Route exact path="/opportunities">
				<Redirect to="/opportunities/summary" />
			</Route>
			<Route
				exact
				path="/opportunities/summary"
				component={OpportunitiesSummary}
			/>
			<Route
				exact
				path="/opportunities/global/retrospective"
				component={OpportunitiesGlobalRetrospective}
			/>
			<Route
				exact
				path="/opportunities/global/summary"
				component={OpportunitiesGlobalSummary}
			/>
			<Route
				exact
				path="/opportunities/details"
				component={OpportunitiesDetails}
			/>
		</RouterSwitch>
	</Page>
);

export default OpportunitiesView;
