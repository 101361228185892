import DocNav from '../components/DocNav';
import OverviewContent from '../components/Content/Overview';
import StrategyContent from '../components/Content/Strategy';
import OpportunitiesContent from '../components/Content/Opportunities';
import ScenarioContent from '../components/Content/Scenario';

const SupportContent = () => {
	return (
		<div className="flex relative pt-4 docs">
			<div className="w-full text-md text-ca-black leading-6">
				<div className="max-w-4xl mr-96">
					<OverviewContent />
				</div>
				<div className="mr-96">
					<hr className="mt-8 mb-4 border-ca-silver" />
				</div>
				<div className="max-w-4xl mr-96">
					<StrategyContent />
				</div>
				<div className="mr-96">
					<hr className="mt-8 mb-4 border-ca-silver" />
				</div>
				<div className="max-w-4xl mr-96">
					<OpportunitiesContent />
				</div>
				<div className="mr-96">
					<hr className="mt-8 mb-4 border-ca-silver" />
				</div>
				<div className="max-w-4xl mr-96">
					<ScenarioContent />
				</div>
			</div>
			<div className="fixed w-96 bottom-0 top-0 right-0">
				<DocNav />
			</div>
		</div>
	);
};

export default SupportContent;
