import { GET_CUMULIO_OPPORTUNITIES_GLOBAL_RETROSPECTIVE_SSO } from '../../shared/api/reports';
import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';

const OpportunitiesGlobalRetrospectiveView = () => {
	return (
		<FullCumulioDashboard
			queryKey="cumulio-opportunities-global-retrospective"
			ssoQuery={GET_CUMULIO_OPPORTUNITIES_GLOBAL_RETROSPECTIVE_SSO}
		/>
	);
};

export default OpportunitiesGlobalRetrospectiveView;
