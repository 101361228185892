import EditIcon from '../../../shared/components/Icons/Edit';

import Note from '../Note';
import MarkerLink from '../MarkerLink';
import Heading from '../Heading';

const StrategyContent = () => (
	<div className="space-y-4" id="strategy">
		<div className="space-y-4">
			<Heading
				element="h2"
				className="pt-2 text-ca-section font-bold text-ca-purple"
				target="strategy"
			>
				Strategy
			</Heading>
			<p>
				The first thing you need to do is{' '}
				<MarkerLink to="/strategy">set up a markdown strategy</MarkerLink>.
			</p>
			<p>A markdown strategy consists of a couple of things:</p>
			<ul className="list-outside pl-4 list-disc">
				<li>
					<MarkerLink to="/strategy/components">Markdown settings</MarkerLink>,
					such as the optimization objective and the end of markdown date{' '}
					<strong>(required)</strong>.
				</li>
				<li>
					<MarkerLink to="/strategy/categories">Product categories</MarkerLink>,
					to enable different strategies for subsets of the assortment{' '}
					<strong>(optional)</strong>.
				</li>
				<li>
					<MarkerLink to="/strategy/business-rules">Business rules</MarkerLink>,
					to take into account strategic and operational constraints{' '}
					<strong>(optional)</strong>.
				</li>
			</ul>
		</div>
		<div className="space-y-4" id="strategy-overview">
			<Heading
				element="h3"
				className="pt-2 text-lg text-ca-purple"
				target="strategy-overview"
			>
				Overview
			</Heading>
			<p>
				<MarkerLink to="/strategy/components">The strategy overview</MarkerLink>{' '}
				is where you set up the required settings for your markdown strategy.
				These settings are needed for the markdown optimization model to run.
			</p>
			<p>
				In this overview, you will see the product categories that you have
				created. See{' '}
				<MarkerLink to="#strategy-product-categories">
					the section on product categories
				</MarkerLink>{' '}
				to find out why and how you would create product categories. For each
				category, the markdown settings have to be set. Click the edit icon{' '}
				<EditIcon className="inline h-4 mb-0.5" /> to modify the following
				settings:
			</p>
			<div className="space-y-4" id="strategy-overview-end-of-markdown-period">
				<Heading
					element="h4"
					className="pt-2 text-sm font-bold text-ca-black"
					target="strategy-overview-end-of-markdown-period"
				>
					End of markdown period
				</Heading>
				<p>
					This is the end of life date for the products on which you are giving
					a markdown. It is the date by which, ideally, you would want to sell
					out the remaining inventory.
				</p>
				<p>
					The markdown optimization model will provide the best possible
					markdown to optimize the results up until this end of markdown date.
				</p>
			</div>
			<div className="space-y-4" id="strategy-overview-optimization-objective">
				<Heading
					element="h4"
					className="pt-2 text-sm font-bold text-ca-black"
					target="strategy-overview-optimization-objective"
				>
					Optimization objective
				</Heading>
				<p>
					This is the main metric towards which the markdown model will
					optimize.
				</p>
				<p>There are several options:</p>
				<ul className="list-outside pl-4 list-disc space-y-4">
					<li>
						<span>Turnover</span>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								Maximize the turnover generated until the end of the markdown
								period.
							</span>
						</p>
					</li>
					<li>
						<span>Gross margin</span>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								Maximize the gross margin generated until the end of the
								markdown period.
							</span>
						</p>
					</li>
					<li>
						<span>Custom</span>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								Set up a custom optimization objective in between turnover (more
								aggressive) and gross margin (less aggressive).
							</span>
						</p>
					</li>
				</ul>
				<p>
					The custom optimization objective is determined by the value assigned
					to rest stock at the end of the markdown period. The more residual
					value we assign to rest stock, the less aggressive the markdowns will
					be. This residual value is determined as a percentage of the cost
					price of the product.
				</p>
				<Note title="Example">
					<div className="space-y-4">
						<p className="italic">
							Imagine we have a product with the following features:
						</p>
						<ul className="list-outside pl-4 list-disc">
							<li>Full sales price = €100</li>
							<li>Cost price = €40</li>
							<li>Remaining inventory = 50 units</li>
						</ul>
						<p>
							Based on the price elasticity and the product lifecycle, the
							prediction model estimates the following sales at every discount
							level until the end of the markdown period. All units that are not
							sold will be rest stock at the end of the markdown period.
						</p>
						<div className="border border-ca-black rounded-lg">
							<table className="w-full table-auto border-collapse text-left">
								<thead>
									<tr>
										<th className="font-bold p-2 border-r border-b border-ca-black">
											<span />
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											0%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											20%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											30%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											40%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											50%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											60%
										</th>
										<th className="font-bold p-2 border-l border-b border-ca-black">
											70%
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-ca-black">
									<tr>
										<td className="p-2 border-r border-ca-black">
											Estimated sales
										</td>
										<td className="p-2 border border-ca-black">14</td>
										<td className="p-2 border border-ca-black">23</td>
										<td className="p-2 border border-ca-black">29</td>
										<td className="p-2 border border-ca-black">36</td>
										<td className="p-2 border border-ca-black">45</td>
										<td className="p-2 border border-ca-black">50</td>
										<td className="p-2 border-l border-ca-black">50</td>
									</tr>
									<tr>
										<td className="p-2 border-r border-ca-black">
											Estimated rest stock
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											36
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											27
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											21
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											14
										</td>
										<td className="p-2 border border-b-0 border-ca-black">5</td>
										<td className="p-2 border border-b-0 border-ca-black">0</td>
										<td className="p-2 border-l border-ca-black">0</td>
									</tr>
								</tbody>
							</table>
						</div>
						<p>
							If we know the estimated sales at every price point, we can easily
							calculate the expected turnover and gross margin as well;
						</p>
						<div className="border border-ca-black rounded-lg">
							<table className="w-full table-auto border-collapse text-left">
								<thead>
									<tr>
										<th className="font-bold p-2 border-r border-b border-ca-black">
											<span />
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											0%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											20%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											30%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											40%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											50%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											60%
										</th>
										<th className="font-bold p-2 border-l border-b border-ca-black">
											70%
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-ca-black">
									<tr>
										<td className="p-2 border-r border-ca-black">Turnover</td>
										<td className="p-2 border border-ca-black">1400</td>
										<td className="p-2 border border-ca-black">1840</td>
										<td className="p-2 border border-ca-black">2030</td>
										<td className="p-2 border border-ca-black">2160</td>
										<td className="p-2 border border-ca-black bg-ca-purple bg-opacity-20">
											2250
										</td>
										<td className="p-2 border border-ca-black">2000</td>
										<td className="p-2 border-l border-ca-black">1500</td>
									</tr>
									<tr>
										<td className="p-2 border-r border-ca-black">
											Gross margin
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											840
										</td>
										<td className="p-2 border border-b-0 border-ca-black bg-ca-purple bg-opacity-20">
											920
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											870
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											720
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											450
										</td>
										<td className="p-2 border border-b-0 border-ca-black">0</td>
										<td className="p-2 border-l border-ca-black">-500</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</Note>
				<p>
					Below we give 3 examples of a potential optimization objective based
					on the residual value assigned to the rest stock.
				</p>
				<Note title="Option 1: 0% residual value (= optimize for turnover)">
					<div className="space-y-4">
						<p>
							If we set the residual value to 0%, it means we don&apos;t assign
							any value to the rest stock at the end of the season. That means
							we are only interested in turnover that can be generated up until
							the end of the markdown period. In this case, a 50% markdown is
							the most optimal.
						</p>
						<div className="border border-ca-black rounded-lg">
							<table className="w-full table-auto border-collapse text-left">
								<thead>
									<tr>
										<th className="font-bold p-2 border-r border-b border-ca-black">
											<span />
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											0%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											20%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											30%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											40%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											50%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											60%
										</th>
										<th className="font-bold p-2 border-l border-b border-ca-black">
											70%
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-ca-black">
									<tr>
										<td className="p-2 border-r border-ca-black">Turnover</td>
										<td className="p-2 border border-ca-black">1400</td>
										<td className="p-2 border border-ca-black">1840</td>
										<td className="p-2 border border-ca-black">2030</td>
										<td className="p-2 border border-ca-black">2160</td>
										<td className="p-2 border border-ca-black">2250</td>
										<td className="p-2 border border-ca-black">2000</td>
										<td className="p-2 border-l border-ca-black">1500</td>
									</tr>
									<tr>
										<td className="p-2 border-r border-ca-black">
											Inventory cost
										</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border-l border-ca-black">0</td>
									</tr>
									<tr>
										<td className="p-2 border-r border-ca-black">
											Residual value rest stock
										</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border-l border-ca-black">0</td>
									</tr>
									<tr className="font-bold">
										<td className="p-2 border-r border-ca-black">Result</td>
										<td className="p-2 border border-b-0 border-ca-black">
											1400
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											1840
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											2030
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											2160
										</td>
										<td className="p-2 border border-b-0 border-ca-black bg-ca-purple bg-opacity-20">
											2250
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											2000
										</td>
										<td className="p-2 border-l border-ca-black">1500</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</Note>
				<Note title="Option 2: 100% residual value (= optimize for gross margin)">
					<div className="space-y-4">
						<p>
							Rest stock at the end of the season can still have some residual
							value. Maybe we can sell it next year, or maybe we can sell it to
							an outlet channel.
						</p>
						<p>
							If we set the residual value to 100% of the cost price, it means
							that the inventory does not decrease in value. The difference
							between the current inventory cost and the residual value of the
							rest stock is equal to the cost of the goods sold. As a result,
							setting the residual value at 100% of the cost price means that we
							are optimizing for gross margin. In this case, a 20% markdown is
							the most optimal.
						</p>
						<p>
							The inventory cost in the example below is the total purchase
							price of the remaining inventory, i.e. 50 remaining pieces
							purchased at €40.
						</p>
						<p>
							The residual value is the remaining rest stock at 100% of the cost
							price (€40). For example at 0% discount, the rest stock is €1440
							(= 36 units x €40).
						</p>
						<div className="border border-ca-black rounded-lg">
							<table className="w-full table-auto border-collapse text-left">
								<thead>
									<tr>
										<th className="font-bold p-2 border-r border-b border-ca-black">
											<span />
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											0%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											20%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											30%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											40%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											50%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											60%
										</th>
										<th className="font-bold p-2 border-l border-b border-ca-black">
											70%
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-ca-black">
									<tr>
										<td className="p-2 border-r border-ca-black">Turnover</td>
										<td className="p-2 border border-ca-black">1400</td>
										<td className="p-2 border border-ca-black">1840</td>
										<td className="p-2 border border-ca-black">2030</td>
										<td className="p-2 border border-ca-black">2160</td>
										<td className="p-2 border border-ca-black">2250</td>
										<td className="p-2 border border-ca-black">2000</td>
										<td className="p-2 border-l border-ca-black">1500</td>
									</tr>
									<tr>
										<td className="p-2 border-r border-ca-black">
											Inventory cost
										</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border-l border-ca-black">-2000</td>
									</tr>
									<tr>
										<td className="p-2 border-r border-ca-black">
											Residual value rest stock
										</td>
										<td className="p-2 border border-ca-black">1440</td>
										<td className="p-2 border border-ca-black">1080</td>
										<td className="p-2 border border-ca-black">840</td>
										<td className="p-2 border border-ca-black">560</td>
										<td className="p-2 border border-ca-black">200</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border-l border-ca-black">0</td>
									</tr>
									<tr className="font-bold">
										<td className="p-2 border-r border-ca-black">Result</td>
										<td className="p-2 border border-b-0 border-ca-black">
											840
										</td>
										<td className="p-2 border border-b-0 border-ca-black bg-ca-purple bg-opacity-20">
											920
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											870
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											720
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											450
										</td>
										<td className="p-2 border border-b-0 border-ca-black">0</td>
										<td className="p-2 border-l border-ca-black">-500</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</Note>
				<Note title="Option 3: 50% residual value">
					<div className="space-y-4">
						<p>
							Often, the residual value of rest stock will be lower than the
							full cost price of an article. Maybe the outlet channel will pay a
							lower price, or there are handling and holding costs involved with
							storing the article until next year. If we value the rest stock at
							50% of the cost price, a 30% markdown is the most optimal.
						</p>
						<p>
							The residual value in this example is the remaining rest stock at
							50% of the cost price (50% x €40). For example at 0% discount, the
							rest stock is €720 (= 36 units x €40 x 50%).
						</p>
						<div className="border border-ca-black rounded-lg">
							<table className="w-full table-auto border-collapse text-left">
								<thead>
									<tr>
										<th className="font-bold p-2 border-r border-b border-ca-black">
											<span />
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											0%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											20%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											30%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											40%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											50%
										</th>
										<th className="font-bold p-2 border-x border-b border-ca-black">
											60%
										</th>
										<th className="font-bold p-2 border-l border-b border-ca-black">
											70%
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-ca-black">
									<tr>
										<td className="p-2 border-r border-ca-black">Turnover</td>
										<td className="p-2 border border-ca-black">1400</td>
										<td className="p-2 border border-ca-black">1840</td>
										<td className="p-2 border border-ca-black">2030</td>
										<td className="p-2 border border-ca-black">2160</td>
										<td className="p-2 border border-ca-black">2250</td>
										<td className="p-2 border border-ca-black">2000</td>
										<td className="p-2 border-l border-ca-black">1500</td>
									</tr>
									<tr>
										<td className="p-2 border-r border-ca-black">
											Inventory cost
										</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border border-ca-black">-2000</td>
										<td className="p-2 border-l border-ca-black">-2000</td>
									</tr>
									<tr>
										<td className="p-2 border-r border-ca-black">
											Residual value rest stock
										</td>
										<td className="p-2 border border-ca-black">720</td>
										<td className="p-2 border border-ca-black">540</td>
										<td className="p-2 border border-ca-black">420</td>
										<td className="p-2 border border-ca-black">280</td>
										<td className="p-2 border border-ca-black">100</td>
										<td className="p-2 border border-ca-black">0</td>
										<td className="p-2 border-l border-ca-black">0</td>
									</tr>
									<tr className="font-bold">
										<td className="p-2 border-r border-ca-black">Result</td>
										<td className="p-2 border border-b-0 border-ca-black">
											120
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											380
										</td>
										<td className="p-2 border border-b-0 border-ca-black bg-ca-purple bg-opacity-20">
											450
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											440
										</td>
										<td className="p-2 border border-b-0 border-ca-black">
											350
										</td>
										<td className="p-2 border border-b-0 border-ca-black">0</td>
										<td className="p-2 border-l border-ca-black">-500</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</Note>
				<Note title="Conclusion">
					<p>
						The lower the value we assign to the rest stock, the more aggressive
						the markdowns will be.
					</p>
				</Note>
				<div>
					<p>
						Next to the optimization objective, you can also take into account
						other costs involved, such as:
					</p>
					<ul className="list-outside pl-4 list-disc">
						<li>Shipping costs</li>
						<li>Return costs</li>
					</ul>
				</div>
				<Note title="Note">
					<p>
						Shipping and return costs need to be calculated per product and/or
						sales region. The default for these costs is €0. Please{' '}
						<MarkerLink href="mailto:pricing@crunchanalytics.be">
							contact Crunch
						</MarkerLink>{' '}
						to set up the correct costs in order to achieve the desired effect.
					</p>
				</Note>
			</div>
		</div>
		<div className="space-y-4" id="strategy-product-categories">
			<Heading
				element="h3"
				className="pt-2 text-lg text-ca-purple"
				target="strategy-product-categories"
			>
				Product categories
			</Heading>
			<p>
				Sometimes the markdown strategy will be different for multiple groups
				within the assortment. You can configure these groups under{' '}
				<MarkerLink to="/strategy/categories">product categories</MarkerLink>.
			</p>
			<Note title="Examples">
				<div className="space-y-4">
					<ul className="list-outside pl-4 space-y-4 list-disc">
						<li>
							For the old collections, we want to optimize on turnover. <br />
							But for the new collections, we want to optimize on margin.
						</li>
						<li>
							The old winter and summer collections have a different end of
							season date.
						</li>
					</ul>
					<p className="flex">
						<span>&#8594;&nbsp;</span>
						<span>
							If this is the case, you can set up product categories in order to
							manage these exceptions. <br /> A product category is created by
							setting 1 or more filters on the entire product assortment.
						</span>
					</p>
				</div>
			</Note>
			<p>Note that there are 2 important rules:</p>
			<ul className="list-outside pl-4 list-disc space-y-4">
				<li>
					<span>
						Every product that is in scope for the markdown recommendations must
						be in a product category.
					</span>
					<p className="flex">
						<span>&#8594;&nbsp;</span>
						<span>
							That&apos;s why there is a default product category that can not
							be deleted. All products that are not in a custom category will be
							assigned to the default category.
						</span>
					</p>
				</li>
				<li>
					<span>Every product can be in only 1 product category.</span>
					<p className="flex">
						<span>&#8594;&nbsp;</span>
						<span>
							That&apos;s why the order of the product categories is important.
						</span>
					</p>
					<p className="flex">
						<span>&#8594;&nbsp;</span>
						<span>
							You can change the order of the product categories. However, the
							default category will always be the last category, containing all
							products which are not in any of the prior categories.
						</span>
					</p>
				</li>
			</ul>
			<Note title="Example">
				<div className="space-y-4">
					<p className="italic">
						Imagine you have 3 product categories: old collections, jackets and
						the default category.
					</p>
					<div>
						<p>If you put them in the following order:</p>
						<ol className="list-outside pl-4 list-decimal">
							<li>Old collections</li>
							<li>Jackets</li>
							<li>Default</li>
						</ol>
					</div>
					<p className="flex">
						<span>&#8594;&nbsp;</span>
						<span>
							<strong>Jackets from the old collection</strong> will be in the{' '}
							<strong>Old collections</strong> category.
						</span>
					</p>
					<div>
						<p>If you put them in the following order:</p>
						<ol className="list-outside pl-4 list-decimal">
							<li>Jackets</li>
							<li>Old collections</li>
							<li>Default</li>
						</ol>
					</div>
					<p className="flex">
						<span>&#8594;&nbsp;</span>
						<span>
							<strong>Jackets from the old collection</strong> will be in the{' '}
							<strong>Jackets</strong> category.
						</span>
					</p>
					<p>
						This is why the number of products within a category changes when
						you change the order.
					</p>
				</div>
			</Note>
		</div>
		<div className="space-y-4" id="strategy-business-rules">
			<Heading
				element="h3"
				className="pt-2 text-lg text-ca-purple"
				target="strategy-business-rules"
			>
				Business rules
			</Heading>
			<p>
				<MarkerLink to="/strategy/business-rules">Business rules</MarkerLink>{' '}
				can be added to ensure that the recommended markdowns are in line with
				your strategic and operational constraints.
			</p>
			<p>
				Business rules are added as <strong>if-then</strong> rules.
			</p>
			<div className="space-y-4" id="strategy-business-rules-if">
				<Heading
					element="h4"
					className="pt-2 text-sm font-bold text-ca-black"
					target="strategy-business-rules-if"
				>
					If
				</Heading>
				<p>
					The <strong>If-condition</strong> specifies a segment of products on
					which the business rule will be applied. If you don&apos;t add a
					segment, the rule will be applied on all products.
				</p>
				<div>
					<p>
						A segment is made up of one or more filters with the following
						characteristics:
					</p>
					<ul className="list-outside pl-4 list-disc">
						<li>A field</li>
						<li>An operator</li>
						<li>A value</li>
					</ul>
				</div>
				<p>
					Note that the operators you can use depend on the field you have
					selected. Some examples:
				</p>
				<ul className="list-outside pl-4 list-disc space-y-4">
					<li>
						<span>
							<strong>Text fields</strong> (brand, collection, etc.)
						</span>
						<p>- Is </p>
						<p>- Is not</p>
					</li>
					<li>
						<span>
							<strong>Numeric fields</strong> (inventory, price, etc.)
						</span>
						<p>- Is less than</p>
						<p>- Is greater than</p>
					</li>
					<li>
						<span>
							<strong>Date fields</strong>
						</span>
						<p>- Is before</p>
						<p>- Is after</p>
					</li>
				</ul>
				<p>
					You can combine multiple filters to create your segment of products.
					The business rule will only be applied on products that fit all filter
					conditions.
				</p>
				<Note title="Example">
					<div className="space-y-4">
						<ul className="list-outside pl-4 list-disc">
							<li>Filter 1: Brand is Adidas</li>
							<li>Filter 2: Price is less than €100</li>
						</ul>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								The business rule will not be applied on an Adidas product of
								€120.
							</span>
						</p>
					</div>
				</Note>
			</div>
			<div className="space-y-4" id="strategy-business-rules-then">
				<Heading
					element="h4"
					className="pt-2 text-sm font-bold text-ca-black"
					target="strategy-business-rules-then"
				>
					Then
				</Heading>
				<p>
					This section can be used to specify the action (= rule) that should be
					applied on the selected products. There are different types of rules:
				</p>
				<ul className="list-outside pl-4 list-disc space-y-4">
					<li>
						<span>Min/max. discount</span>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								Specify <strong>a minimum and/or maximum value</strong> for the
								recommended markdown
							</span>
						</p>
					</li>
					<li>
						<span>Fixed discount</span>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								Specify <strong>a fixed value </strong>for the recommended
								markdown. All products in the segment will receive this
								markdown.
							</span>
						</p>
					</li>
					<li>
						<span>Possible discounts</span>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								Specify which <strong>markdown levels</strong> are allowed. You
								can enable or disable markdown levels per 5% between 0% and 95%.
							</span>
						</p>
					</li>
					<li>
						<span>Built-in rules</span>
						<div className="flex">
							<span>&#8594;&nbsp;</span>
							<div>
								These are <strong>preset business rules</strong> that can be
								enabled, such as:
								<ul className="ml-5 list-outside pl-4 list-disc">
									<li>
										Markdowns can never decrease:{' '}
										<span className="italic">
											the recommended markdown will never be lower than the
											current markdown.
										</span>
									</li>
									<li>
										Products cannot have a negative margin:{' '}
										<span className="italic">
											the price after markdown will never be below the cost
											price.
										</span>
									</li>
								</ul>
								<p>
									Custom business rules can be added to this list if needed.
									Please{' '}
									<MarkerLink href="mailto:pricing@crunchanalytics.be">
										contact Crunch
									</MarkerLink>{' '}
									in order to specify the details.
								</p>
							</div>
						</div>
					</li>
				</ul>
				<Note title="Example">
					<div className="space-y-4">
						<p className="italic">
							Imagine you have the following 2 business rules:
						</p>
						<ul className="list-outside pl-4 space-y-4 list-disc">
							<li>
								<span>
									<strong>Rule 1</strong>
								</span>
								<p>- If: (empty)</p>
								<p>- Then: max. discount = 70%</p>
							</li>
							<li>
								<span>
									<strong>Rule 2</strong>
								</span>
								<p>- If: Brand = Nike</p>
								<p>- Then: max. discount = 30%</p>
							</li>
						</ul>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								All products will have a maximum recommended discount of 70%,
								since we didn&apos;t specify a segment for business rule 1.
								However, all Nike products will have a maximum recommended
								discount of 30%.
							</span>
						</p>
					</div>
				</Note>

				<ul className="list-outside pl-4 list-disc space-y-4">
					<li>
						<span>Discount distribution</span>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								Specify limits to how many products or stock should be
								discounted at a certain value. <br />
								<span className="italic">
									{' '}
									E.g. at least 10% of stock should be discounted at a 50%
									markdown.
								</span>
							</span>
						</p>
					</li>
				</ul>
				<p className="ml-4">
					This is a rule which cannot be evaluated for an individual product,
					but instead <strong>for an entire subset of the assortment.</strong>
				</p>
				<Note title="Example">
					<div className="space-y-4">
						<p className="italic">
							Imagine you have the following 2 business rules:
						</p>
						<ul className="list-outside pl-4 space-y-4 list-disc">
							<li>
								<span>
									<strong>Rule 1</strong>
								</span>
								<p>
									- If: Category_1 is in (&quot;Jeans&quot;,
									&quot;T-shirt&quot;, &quot;Jacket&quot;, &quot;Shoes&quot;)
								</p>
								<p>
									- Then: Discount distribution = Min 10% of stock should be
									discounted at 50%
								</p>
							</li>
							<li>
								<span>
									<strong>Rule 2</strong>
								</span>
								<p>
									- If: Category_1 is in (&quot;Jeans&quot;,
									&quot;T-shirt&quot;, &quot;Jacket&quot;, &quot;Shoes&quot;)
								</p>
								<p>
									- Then: Discount distribution = Min 10% of stock should be
									discounted at 50% (apply rule for every Category_1)
								</p>
							</li>
						</ul>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								For rule 1, 10% of stock across all groups will be discounted at
								50%. However, it could be that 30% of T-shirt stock is
								discounted at 50% and only 5% of Jackets stock is discounted at
								50%.
							</span>
						</p>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								For rule 2, the discount distribution will be applied to all
								individual sub groups. So at least 10% of stock will be
								discounted at 50% for Jeans, T-shirts, Jackets and Shoes.
							</span>
						</p>
					</div>
				</Note>
			</div>
			<div
				className="space-y-4"
				id="strategy-business-rules-conflicting-business-rules"
			>
				<Heading
					element="h4"
					className="pt-2 text-sm font-bold text-ca-black"
					target="strategy-business-rules-conflicting-business-rules"
				>
					Conflicting business rules
				</Heading>
				<p>
					It is possible that certain business rules have conflicting actions.
				</p>
				<Note title="Example">
					<ul className="list-outside pl-4 list-disc">
						<li>Fixed discount is 50% and max. discount is 30%.</li>
						<li>Max. discount is 30% and markdowns can never decrease. </li>
					</ul>
				</Note>
				<p>
					This will result in a conflict if the current markdown is higher than
					30%.
				</p>
				<p>
					These conflicts can be managed by determining the order of the
					business rules. In the overview table, you can drag business rules up
					or down to change the order in which they are applied. The business
					rule at the top of the overview table is executed first. This means
					that a rule which is applied later on can potentially override the
					result.
				</p>
				<Note title="Example">
					<div className="space-y-4">
						<p className="italic">
							Imagine we have 2 business rules that are applied on the same
							selection of products.
						</p>
						<p>Option 1</p>
						<ul className="list-outside pl-4 list-disc">
							<li>Fixed discount = 50%</li>
							<li>Max. discount = 30%</li>
						</ul>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								In this case, the max. discount will be applied after the fixed
								discount. <br />
								Thus, the recommendations will not be higher than 30%.
							</span>
						</p>
						<p>Option 2</p>
						<ul className="list-outside pl-4 list-disc">
							<li>Max. discount = 30%</li>
							<li>Fixed discount = 50%</li>
						</ul>
						<p className="flex">
							<span>&#8594;&nbsp;</span>
							<span>
								In this case, the fixed discount will be applied after the max.
								discount. <br />
								Thus, the recommendations will all be 50%.
							</span>
						</p>
					</div>
				</Note>
			</div>
			<div
				className="space-y-4"
				id="strategy-business-rules-enabling-business-rules"
			>
				<Heading
					element="h4"
					className="pt-2 text-sm font-bold text-ca-black"
					target="strategy-business-rules-enabling-business-rules"
				>
					Enabling business rules
				</Heading>
				<p>
					After adding a business rule, you can go to the strategy overview to
					enable it. Remember that business rules will only be applied if they
					are enabled in your markdown strategy.
				</p>
			</div>
		</div>
	</div>
);

export default StrategyContent;
