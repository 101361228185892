import PropTypes from 'prop-types';

const FilterTag = ({ field, value, onClick }) => (
	<button
		className="p-2 text-sm text-ca-black bg-ca-silver rounded-lg transition-colors leading-none focus:outline-none focus-visible:ring-4 focus-visible:ring-opacity-10 focus-visible:ring-ca-purple focus-visible:bg-ca-silver-a hover:bg-ca-silver-a active:bg-ca-silver-a"
		type="button"
		onClick={onClick}
	>
		{field} <span className="text-ca-gray">is</span>{' '}
		{Array.isArray(value) ? value?.join(', ') : value}
	</button>
);

FilterTag.propTypes = {
	field: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]).isRequired,
	onClick: PropTypes.func,
};

FilterTag.defaultProps = {
	onClick: () => {},
};

export default FilterTag;
