import { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { GET_CATEGORIES_FILTERS } from '../../shared/api/categories';

import useOnClickOutside from '../../shared/hooks/useOnClickOutside';
import useKeyPress from '../../shared/hooks/useKeyPress';
import useChannelQuery from '../../shared/hooks/useChannelQuery';

import FilterButton from '../../shared/components/Filter/FilterButton';
import FilterFieldMenu from '../../shared/components/Filter/FilterFieldMenu';
import FilterValueMenu from '../../shared/components/Filter/FilterValueMenu';
import FilterTag from '../../shared/components/Filter/FilterTag';

const ProductsFilter = ({ filters, onChange }) => {
	const ref = useRef();
	const [filtersVisible, setFiltersVisible] = useState(false);
	const [[selectedField, selectedType], setSelectedField] = useState([]);
	const { isLoading: isFiltersLoading, data = [] } = useChannelQuery(
		'categories-filters',
		GET_CATEGORIES_FILTERS,
		{
			staleTime: 5 * 60 * 1000,
		}
	);

	const handleFiltersClose = () => {
		setSelectedField([]);
		setFiltersVisible(false);
	};

	const handleFiltersOpen = () => {
		setSelectedField([]);
		setFiltersVisible(true);
	};

	useOnClickOutside(ref, handleFiltersClose);
	useKeyPress('Escape', handleFiltersClose);

	const handleFieldChange = (id, type) => {
		const filter = data.find((f) => f.id === id);
		setSelectedField([filter, type]);
	};

	const handleFilterChange = (value) => {
		onChange((f) => new Map(f.set(selectedField?.id, value)));
		handleFiltersClose();
	};

	const handleFilterRemove = (filter) => {
		onChange((f) => {
			const newMap = new Map(f);
			newMap.delete(filter);
			return newMap;
		});
		handleFiltersClose();
	};

	const handleFiltersReset = () => {
		onChange(new Map());
		handleFiltersClose();
	};

	const activeFilters = useMemo(() => {
		if (!data?.length) return [];

		return Array.from(filters.entries()).map(([field, val]) => {
			const filter = data.find((f) => f.id === field);

			let value = val;

			if (filter.type === 'Range') {
				let min;
				let max;

				if (Array.isArray(val)) {
					[min, max] = val;
				} else {
					[min, max] = val.split(',');
				}

				if (filter.percentage) {
					value = `${min * 100}% - ${max * 100}%`;
				} else {
					value = `${min} - ${max}`;
				}
			} else if (!Array.isArray(value)) {
				value = value.split(',');
			}

			return {
				id: filter.id,
				name: filter.name,
				value,
			};
		});
	}, [filters, data]);

	// filter out currently active filters
	const availableFilters = data.filter(
		(f) => !activeFilters.map(({ id }) => id).includes(f.id)
	);

	return (
		<div className="flex justify-between items-center">
			<div className="flex items-center" ref={ref}>
				<div className="relative mr-3">
					<FilterButton
						disabled={
							(!!activeFilters.length && !availableFilters.length) ||
							isFiltersLoading
						}
						loading={isFiltersLoading}
						onClick={handleFiltersOpen}
					/>
					<FilterFieldMenu
						options={availableFilters}
						loading={isFiltersLoading}
						visible={filtersVisible}
						onChange={(fieldId) => handleFieldChange(fieldId, 'menu')}
						onReset={activeFilters.length ? handleFiltersReset : null}
					/>
					<FilterValueMenu
						key={selectedField?.id}
						field={selectedField?.name || ''}
						value={filters.get(selectedField?.id) || ''}
						options={
							selectedField?.checklist_options ||
							selectedField?.list_options ||
							selectedField?.range_options
						}
						type={selectedField?.type}
						percentage={selectedField?.percentage}
						// only show dropdown if there is an active field, and the type is 'menu'
						visible={!!selectedField && selectedType === 'menu'}
						onApply={handleFilterChange}
						onCancel={handleFiltersClose}
						className="left-full ml-60"
					/>
				</div>
				<div className="flex flex-wrap gap-3">
					{activeFilters.map((f) => (
						<div key={f.id} className="relative inline">
							<FilterTag
								onClick={() => {
									setFiltersVisible(false);
									handleFieldChange(f.id, 'tag');
								}}
								field={f.name}
								value={f.value}
							/>
							<FilterValueMenu
								key={selectedField?.id}
								field={selectedField?.name || ''}
								value={f.value}
								options={
									selectedField?.checklist_options ||
									selectedField?.list_options ||
									selectedField?.range_options
								}
								type={selectedField?.type}
								percentage={selectedField?.percentage}
								visible={
									// only show dropdown if currently active field is this one, and the type is 'tag'
									selectedField?.id === f.id && selectedType === 'tag'
								}
								onApply={handleFilterChange}
								onRemove={() => handleFilterRemove(f.id)}
								className="left-0"
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

ProductsFilter.propTypes = {
	filters: PropTypes.instanceOf(Map),
	onChange: PropTypes.func,
};

ProductsFilter.defaultProps = {
	filters: new Map(),
	onChange: () => {},
};

export default ProductsFilter;
