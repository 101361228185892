import { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import dayjs from 'dayjs';

import {
	CREATE_CATEGORY,
	GET_CATEGORIES_PRODUCTS,
	GET_CATEGORIES_SCHEMA,
	GET_CATEGORY,
	UPDATE_CATEGORY,
} from '../../../shared/api/categories';

import { useProductCategoriesHeadings as useHeadings } from '../../../shared/data/headings';
import useChannelQuery from '../../../shared/hooks/useChannelQuery';

import Text from '../../../shared/components/Text/Text';
import Tag from '../../../shared/components/Tag/Tag';
import Button from '../../../shared/components/Button/Button';
import Table from '../../../shared/components/Table/Table';
import Title from '../../../shared/components/Title/Title';
import TextInput from '../../../shared/components/TextInput/TextInput';
import TableHeaderButton from '../../../shared/components/TableHeaderButton/TableHeaderButton';
import InputWithLabel from '../../../shared/components/InputWithLabel/InputWithLabel';
import LinearProgress from '../../../shared/components/Progress/LinearProgress';

import ProductsFilter from '../../components/ProductsFilter';

const ProductCategoriesCreateEdit = () => {
	const history = useHistory();
	const { categoryId } = useParams();
	const isEditMode = !!categoryId;
	const { control, formState, setValue, handleSubmit } = useForm({
		defaultValues: {
			name: '',
		},
	});
	const [filters, setFilters] = useState(new Map());
	const [pagination, setPagination] = useState({ page: 1, size: 50 });
	const [sort, setSort] = useState({
		key: 'pid',
		direction: 'desc',
	});
	const { disabledHeadings, toggleHeading, resetHeadings } = useHeadings();
	const queryClient = useQueryClient();

	const {
		isFetching: isCategoryFetching,
		data: category,
		channel,
	} = useChannelQuery(
		['category', categoryId],
		() => GET_CATEGORY(categoryId),
		{
			enabled: isEditMode,
			onError: async ({ response }) => {
				if (response?.status === 404) {
					history.push('/strategy/categories');
				}
			},
			retry: false,
		}
	);

	useEffect(() => {
		if (category) {
			setValue('id', category?.id);
			setValue('name', category?.name);

			const filterData = (category?.filters || []).map((raw) => {
				return [raw.filter_id, raw.values];
			});

			setFilters(new Map(filterData));
		}
	}, [category]);

	const { isLoading: isSchemaLoading, data: schema = [] } = useChannelQuery(
		'categories-schema',
		GET_CATEGORIES_SCHEMA,
		{
			staleTime: 5 * 60 * 1000,
		}
	);

	// convert id to bq_name for sorting filter
	const getSortParams = () => {
		const heading = schema.find(({ id }) => id === sort.key);

		if (!heading) {
			return null;
		}

		return ['sort', `${heading.id}:${sort.direction}`];
	};

	const { isFetching: isDataFetching, data } = useChannelQuery(
		['products', pagination, Object.fromEntries(filters), sort, categoryId],
		() => {
			const sortParams = getSortParams();

			return GET_CATEGORIES_PRODUCTS(
				new URLSearchParams([
					['page', pagination.page],
					['size', pagination.size],
					...(sortParams ? [sortParams] : []),
					...Array.from(filters.entries()).map(([id, value]) => [
						'filter',
						`"id":"${id}","value":${JSON.stringify(value)}`,
					]),
				])
			);
		},
		{
			staleTime: 5 * 60 * 1000,
			keepPreviousData: true,
			enabled: !isEditMode || !isCategoryFetching,
		}
	);

	const {
		isLoading: isCreateLoading,
		isSuccess: isCreateSuccess,
		mutate: createCategory,
	} = useMutation(CREATE_CATEGORY, {
		onMutate: async (payload) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries([channel, 'categories']);

			// Snapshot the previous value
			const previousCategories = queryClient.getQueryData([
				channel,
				'categories',
			]);

			if (previousCategories) {
				// Optimistically update to the new value
				queryClient.setQueryData([channel, 'categories'], (old) => {
					const defaultCategory = old.items.pop();

					return {
						...old,
						items: [
							...(old.items || []),
							{ changeable: true, priority: defaultCategory, ...payload }, // insert before default category and update both priorities
							{ ...defaultCategory, priority: defaultCategory + 1 },
						],
						total: old.total + 1,
					};
				});
			}

			// Return a context object with the snapshotted value
			return { previousCategories };
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, payload, context) => {
			queryClient.setQueryData(
				[channel, 'categories'],
				context.previousCategories
			);
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries([channel, 'categories']);
		},
	});

	const {
		isLoading: isEditLoading,
		isSuccess: isEditSuccess,
		mutate: editCategory,
	} = useMutation((payload) => UPDATE_CATEGORY(payload?.id, payload), {
		onMutate: async (payload) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries([channel, 'categories']);

			// Snapshot the previous value
			const previousCategories = queryClient.getQueryData([
				channel,
				'categories',
			]);

			if (previousCategories) {
				// Optimistically update to the new value
				queryClient.setQueryData([channel, 'categories'], (old) => {
					return {
						...old,
						items: (old?.items || []).map((item) => {
							if (item.id === payload.id) {
								return {
									...item,
									...payload,
								};
							}

							return item;
						}),
					};
				});
			}

			// Return a context object with the snapshotted value
			return { previousCategories };
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, payload, context) => {
			queryClient.setQueryData(
				[channel, 'categories'],
				context.previousCategories
			);
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries([channel, 'categories']);
		},
	});

	const handleAnimationEnded = () => {
		if (isCreateSuccess || isEditSuccess) {
			history.push('/strategy/categories');
		}
	};

	const onSubmit = (d) => {
		const payload = {
			...d,
			filters: Array.from(filters.entries()).map(([id, value]) => ({
				filter_id: id,
				values: value,
			})),
		};

		return isEditMode ? editCategory(payload) : createCategory(payload);
	};

	const headings = useMemo(
		() =>
			schema.map((s) => ({
				id: s.id,
				label: s.name,
				tooltip: s.tooltip,
				sortable: s.sortable,
				align: 'center',
				enabled: !disabledHeadings.includes(s.id),
			})),
		[schema, disabledHeadings]
	);

	const handleFiltersChange = (newFilters) => {
		setFilters(newFilters);
		// go back to page 1
		setPagination((p) => ({ ...p, page: 1 }));
	};

	const handleSortChange = (s) => {
		setSort(s);
		// go back to page 1
		setPagination((p) => ({ ...p, page: 1 }));
	};

	const handlePaginationChange = (pag) => {
		setPagination(pag);
	};

	const renderCell = useCallback(
		(row, columnId) => {
			const { field_type: fieldType } = schema.find(
				({ id }) => id === columnId
			);

			if (fieldType === 'date') {
				return dayjs(row?.[columnId]).format('DD/MM/YYYY');
			}

			if (fieldType === 'image') {
				return row?.[columnId] ? (
					<img className="inline-block w-16" src={row?.[columnId]} alt="" />
				) : null;
			}

			if (columnId === 'product_group') {
				return (
					<div className="flex justify-center items-center flex-wrap gap-1">
						{row?.[columnId]?.map((group) => (
							<Tag key={`${row?.product_id}_${group}`} label={group} />
						))}
					</div>
				);
			}

			return row?.[columnId]?.toString();
		},
		[data]
	);

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress
					visible={
						isCategoryFetching ||
						isDataFetching ||
						isSchemaLoading ||
						isCreateLoading ||
						isEditLoading
					}
					onAnimationEnded={handleAnimationEnded}
				/>
			</div>
			<div className="py-6">
				{isEditMode ? (
					<Title type="section">Edit category</Title>
				) : (
					<Title type="section">New category</Title>
				)}
				<div className="mt-3">
					<Text type="secondary">
						Give this category a name and select the products you want to assign
						to it.
					</Text>
					<form className="mt-6 space-y-5" onSubmit={handleSubmit(onSubmit)}>
						<InputWithLabel
							label="Category name"
							htmlFor="name"
							labelClassName="w-64 mb-2 md:mb-0"
						>
							<Controller
								name="name"
								control={control}
								rules={{ required: 'Required field' }}
								render={({ field }) => (
									<TextInput
										id="title"
										className="w-full sm:w-64"
										value={field.value}
										onChange={(val) => field.onChange(val)}
										error={formState?.errors?.name?.message}
									/>
								)}
							/>
						</InputWithLabel>
						<div className="flex items-center w-128 justify-between">
							<div>
								{!isDataFetching &&
									Object.prototype.hasOwnProperty.call(data || {}, 'total') && (
										<Text>
											<strong>{data?.total}</strong> products match the selected
											filters
										</Text>
									)}
							</div>
							<div className="space-x-5">
								<Button
									variant="link"
									onClick={() => history.push('/strategy/categories')}
								>
									Cancel
								</Button>
								<Button
									type="submit"
									disabled={
										isCreateLoading ||
										isEditLoading ||
										isCreateSuccess ||
										isEditSuccess ||
										!filters.size
									}
								>
									Save
								</Button>
							</div>
						</div>
					</form>
					<hr className="mt-6 mb-6 border-ca-silver" />
					<div className="relative">
						<div className="flex justify-between">
							<div className="grow self-center">
								<ProductsFilter
									filters={filters}
									onChange={handleFiltersChange}
								/>
							</div>
							<div className="flex items-center">
								<TableHeaderButton
									headings={headings}
									loading={isSchemaLoading}
									onChange={toggleHeading}
									onReset={disabledHeadings.length ? resetHeadings : null}
								/>
							</div>
						</div>
						<div className="mt-6">
							<Table
								headings={headings.filter(({ enabled }) => enabled)}
								rows={data?.items}
								rowKey="product_id"
								renderCell={renderCell}
								pagination={{
									currentPage: pagination?.page,
									items: data?.total,
									itemsPerPage: pagination?.size,
									itemsPerPageOptions: [10, 25, 50, 100],
								}}
								onPageChange={(page) =>
									handlePaginationChange((p) => ({ ...p, page }))
								}
								onItemsPerPageChange={(size) =>
									handlePaginationChange({ size, page: 1 })
								}
								sort={sort}
								onSortChange={handleSortChange}
								loading={isDataFetching}
								itemsLoading={pagination?.size}
								emptyState="No products found for the selected filters..."
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductCategoriesCreateEdit;
