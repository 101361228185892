import { Route } from 'react-router-dom';

import ScenarioOverview from './ScenarioOverview';
import TestStrategyOverview from './TestStrategyOverview';

import StrategyComponentsEdit from '../../strategy/views/Components/Edit';

import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import Page from '../../shared/components/Page/Page';
import Title from '../../shared/components/Title/Title';
import Text from '../../shared/components/Text/Text';
import ChannelSelector from '../../shared/components/ChannelSelector/ChannelSelector';

const ScenarioView = () => {
	return (
		<Page>
			<div className="relative flex justify-between">
				<Title>Scenario</Title>
				<ChannelSelector />
			</div>
			<Text className="my-2 leading-none" size="text-lg" type="secondary">
				Experiment, compare and decide.
			</Text>
			<hr className="mt-6 border-ca-silver" />
			<RouterSwitch>
				<Route exact path="/scenario" component={ScenarioOverview} />
				<Route
					exact
					path="/scenario/strategy/test"
					component={TestStrategyOverview}
				/>
				<Route
					exact
					path="/scenario/strategy/:strategyId/components/:componentId/edit"
					component={StrategyComponentsEdit}
				/>
			</RouterSwitch>
		</Page>
	);
};

export default ScenarioView;
