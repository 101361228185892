import api from './api';

export const GET_BUSINESS_RULES_TAGS = () => {
	return api.get('api/v2/business-rules/tags').json();
};

export const CREATE_BUSINESS_RULES_TAG = (payload) => {
	return api
		.post('api/v2/business-rules/tags', {
			json: payload,
		})
		.json();
};

export const GET_BUSINESS_RULES = () => {
	return api.get('api/v2/business-rules').json();
};

export const GET_BUSINESS_RULE = (id) => {
	return api.get(`api/v2/business-rules/${id}`).json();
};

export const DELETE_BUSINESS_RULE = (id) => {
	return api.delete(`api/v2/business-rules/${id}`).json();
};

export const CREATE_BUSINESS_RULE = (payload) => {
	return api
		.post('api/v2/business-rules', {
			json: payload,
		})
		.json();
};

export const UPDATE_BUSINESS_RULES_PRIORITIES = (payload) => {
	return api
		.post(`api/v2/business-rules/priorities`, {
			json: payload,
		})
		.json();
};

export const UPDATE_BUSINESS_RULE = (id, payload) => {
	return api
		.put(`api/v2/business-rules/${id}`, {
			json: payload,
		})
		.json();
};

export const GET_BUSINESS_RULES_ACTION_TYPES = () => {
	return api.get('api/v2/business-rules/action-types').json();
};

export const GET_BUSINESS_RULES_GLOBAL_ACTIONS = () => {
	return api.get('api/v2/business-rules/globalactions').json();
};

export const GET_BUSINESS_RULES_GLOBAL_FIELDS = () => {
	return api.get('api/v2/business-rules/fields').json();
};

export const GET_BUSINESS_RULES_REPEATING_FIELDS = () => {
	return api.get('api/v2/business-rules/repeatingfields').json();
};
