import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { useReports } from '../../../domains/domains';
import { GET_INVENTORY_ALLOCATION_REPORT } from '../../../shared/api/inventory-allocation-reports';
import { Configuration } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import {
	InventoryAllocationReport,
	InventoryAllocationReportStatus,
} from '../../../shared/models/inventoryAllocationReport';
import ConfigurationForm from './configurationForm';
import InventoryAllocationReportDetail from './InventoryAllocationReportDetail';
import InventoryAllocationReportInProgress from './InventoryAllocationReportInProgress';
import InventoryAllocationReportNoMovements from './InventoryAllocationReportNoMovements';
import { ScopeTypeDTO } from '../../../shared/models/schema';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	id: InventoryAllocationReport['id'];
	isAddingDraft?: boolean;
	addDraft: (configuration?: Configuration) => void;
	rootURL: string;
}

const DraftReport: FunctionComponent<Props> = ({
	inventoryAllocationId,
	id,
	rootURL,
	isAddingDraft,
	addDraft,
}) => {
	const result = useQuery(['reports', id], () =>
		GET_INVENTORY_ALLOCATION_REPORT(inventoryAllocationId, id)
	);
	const {
		isLoading,
		entity: draft,
		update,
		startAlgorithm,
	} = useReports(result);

	const saveConfiguration = (configuration: Configuration): void => {
		update({
			id,
			configuration,
			inventoryAllocationId,
			title: draft?.title || '',
		});
	};

	const copyConfigurationToNewDraft = (configuration: Configuration): void => {
		addDraft(configuration);
	};

	if (
		isLoading ||
		draft?.status === InventoryAllocationReportStatus.InProgress
	) {
		<InventoryAllocationReportInProgress
			addDraft={addDraft}
			isAddingDraft={isAddingDraft}
		/>;
	}

	if (draft?.status === InventoryAllocationReportStatus.NoSuggestedMoves) {
		<InventoryAllocationReportNoMovements
			addDraft={addDraft}
			isAddingDraft={isAddingDraft}
		/>;
	}

	if (
		draft?.status === InventoryAllocationReportStatus.Draft ||
		draft?.status === InventoryAllocationReportStatus.Error
	) {
		return (
			<ConfigurationForm
				isLoading={isLoading}
				onChange={saveConfiguration}
				defaultValues={draft.configuration}
				inventoryAllocationId={inventoryAllocationId}
				defaultFilters={
					(
						result.data?.scopes?.find(
							({ scope_type }) => scope_type === ScopeTypeDTO.ProductScope
						) as any
					)?.filters
				}
				reportId={id}
				validationMessage={
					draft?.status === InventoryAllocationReportStatus.Error
						? draft.message ||
						  'An unknown error ocurred while calculating the optimal inventory allocation.'
						: undefined
				}
				onSubmit={() => startAlgorithm(inventoryAllocationId, draft.id)}
			/>
		);
	}

	return (
		<InventoryAllocationReportDetail
			inventoryAllocationId={inventoryAllocationId}
			id={id}
			rootURL={rootURL}
			onCopyConfiguration={copyConfigurationToNewDraft}
			onAddDraft={addDraft}
			isCopyingConfiguration={isAddingDraft}
			isAddingDraft={isAddingDraft}
		/>
	);
};

export default DraftReport;
