import api from './api';

export const GET_CUMULIO_RETROSPECTIVE_SSO = () => {
	return api.get(`api/v2/reports/cumulio/retrospective/sso`).json();
};

export const GET_CUMULIO_SCENARIO_SSO = () => {
	return api.get('api/v2/reports/cumulio/scenario/sso').json();
};

export const GET_CUMULIO_OPPORTUNITIES_OVERVIEW_SSO = () => {
	return api.get('api/v2/reports/cumulio/opportunities/overview/sso').json();
};

export const GET_CUMULIO_OPPORTUNITIES_GLOBAL_RETROSPECTIVE_SSO = (week) => {
	if (!week) {
		return null;
	}

	return api
		.get(`api/v2/reports/cumulio/opportunities/global/retrospective/sso`)
		.json();
};

export const GET_CUMULIO_OPPORTUNITIES_GLOBAL_SUMMARY_SSO = () => {
	return api
		.get('api/v2/reports/cumulio/opportunities/global/overview/sso')
		.json();
};
export const GET_CUMULIO_OPPORTUNITIES_PRODUCT_SSO = (productId) => {
	return api
		.get(`api/v2/reports/cumulio/opportunities/${productId}/sso`)
		.json();
};

export const GET_CUMULIO_ACTUALS_SALES_SSO = () => {
	return api.get(`api/v2/reports/cumulio/actuals/sales/sso`).json();
};

export const GET_CUMULIO_ACTUALS_INVENTORY_SSO = () => {
	return api.get(`api/v2/reports/cumulio/actuals/inventory/sso`).json();
};

export const GET_CUMULIO_SUPPORT_VALIDATION_SSO = () => {
	return api.get(`api/v2/reports/cumulio/support/validation/sso`).json();
};
