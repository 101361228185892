import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
	CREATE_STRATEGY,
	GET_STRATEGIES_SUMMARY,
	GET_TEST_STRATEGY,
} from '../../shared/api/strategies';
import { GET_CUMULIO_SCENARIO_SSO } from '../../shared/api/reports';

import useChannelQuery from '../../shared/hooks/useChannelQuery';

import Title from '../../shared/components/Title/Title';
import Text from '../../shared/components/Text/Text';
import LinearProgress from '../../shared/components/Progress/LinearProgress';

import Card from '../components/Card';
import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';

const ScenarioOverview = () => {
	const history = useHistory();

	const {
		isLoading,
		isFetching,
		data: testStrategy,
		refetch: refetchStrategies,
	} = useChannelQuery('test-strategy', GET_TEST_STRATEGY);

	const {
		isFetching: isSummaryCurrentFetching,
		isPlaceholderData: isSummaryCurrentPlaceholderData,
		data: summaryCurrent,
	} = useChannelQuery(
		'strategy-summary-current',
		() => GET_STRATEGIES_SUMMARY('current'),
		{
			staletime: 5 * 60 * 1000,
			placeholderData: {
				average_markdown: 20,
				expected_revenue: '€1,000,000',
				expected_margin: '€400,000',
				expected_rest_stock: 55,
			},
		}
	);
	const {
		isFetching: isSummaryOptimalFetching,
		isPlaceholderData: isSummaryOptimalPlaceholderData,
		data: summaryOptimal,
	} = useChannelQuery(
		'strategies-summary-optimal',
		() => GET_STRATEGIES_SUMMARY('optimal'),
		{
			placeholderData: {
				average_markdown: 25,
				expected_revenue: '€1,200,000',
				expected_margin: '€450,000',
				expected_rest_stock: 37,
			},
		}
	);
	const {
		isFetching: isSummaryTestFetching,
		isPlaceholderData: isSummaryTestPlaceholderData,
		data: summaryTest,
		refetch: refetchSummary,
	} = useChannelQuery(
		'strategies-summary-test',
		() => GET_STRATEGIES_SUMMARY('test'),
		{
			placeholderData: {
				average_markdown: 30,
				expected_revenue: '€1,400,000',
			},
		}
	);

	const { isLoading: isCreateLoading, mutate: createStrategy } = useMutation(
		CREATE_STRATEGY,
		{
			onSuccess: () => {
				refetchStrategies();
				refetchSummary();
			},
		}
	);

	const percentageHelper = (pct) => {
		return Number.isNaN(parseInt(pct, 10)) ? 'Unknown' : `${pct}%`;
	};

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress
					visible={
						isFetching ||
						isSummaryCurrentFetching ||
						isSummaryOptimalFetching ||
						isSummaryTestFetching
					}
				/>
			</div>
			<div className="relative py-6">
				<div className="my-6 flex flex-wrap justify-start gap-6">
					<div className="mt-8 grow max-w-xs hidden xl:block">
						<div className="invisible">
							<Title type="section">Legend</Title>
						</div>
						<div className="flex flex-col mt-6">
							<Text type="secondary" className="pb-3 pr-0">
								Average markdown
							</Text>
						</div>
					</div>
					<div className="flex justify-center gap-6 w-full flex-wrap xl:w-auto">
						<Card
							title="Current markdown"
							markdown={percentageHelper(summaryCurrent?.average_markdown)}
							type="secondary"
							loading={
								isSummaryCurrentPlaceholderData && isSummaryCurrentFetching
							}
						/>
						<Card
							title="Active strategy"
							markdown={percentageHelper(summaryOptimal?.average_markdown)}
							active
							loading={
								isSummaryOptimalPlaceholderData && isSummaryOptimalFetching
							}
						/>
						<Card
							title="Test strategy"
							markdown={percentageHelper(summaryTest?.average_markdown)}
							action={testStrategy ? 'edit' : 'create'}
							onCreate={createStrategy}
							onEdit={() => history.push('/scenario/strategy/test')}
							loading={
								isLoading ||
								(isSummaryTestPlaceholderData && isSummaryTestFetching) ||
								isCreateLoading
							}
						/>
					</div>
				</div>
				<hr className="mt-12 border-ca-silver" />
				<FullCumulioDashboard
					queryKey="cumulio-scenario"
					ssoQuery={GET_CUMULIO_SCENARIO_SSO}
					showLoading={false}
				/>
			</div>
		</>
	);
};

export default ScenarioOverview;
