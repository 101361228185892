import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Nav from '../../shared/components/Nav/Nav';

const NAV_ITEMS = [
	{
		id: 'overview',
		label: 'Overview',
		to: '/strategy',
	},
	{
		id: 'product-categories',
		label: 'Product categories',
		to: '/strategy/categories',
	},
	{
		id: 'business-rules',
		label: 'Business rules',
		to: '/strategy/business-rules',
	},
];

const StrategyNav = () => {
	const { pathname } = useLocation();
	const match = useMemo(
		() =>
			[...NAV_ITEMS]
				// we sort the nav items from most specific to least specific
				// otherwise every route would match the overview `/strategy`
				.sort((a, b) => b.to.split('/').length - a.to.split('/').length)
				.find((item) => pathname.startsWith(item.to)),

		[pathname]
	);

	return <Nav active={match?.id} items={NAV_ITEMS} />;
};

export default StrategyNav;
