import clsx from 'clsx';

import { FunctionComponent } from 'react';
import CrossIcon from '../Icons/Cross';

export enum TagColor {
	Blue,
	Green,
	Grey,
	Orange,
	Purple,
	Red,
	White,
}

const COLOR_CLASSES: Record<TagColor, string> = {
	[TagColor.Blue]: 'text-ca-blue border-ca-blue',
	[TagColor.Grey]: 'text-ca-grey border-ca-grey',
	[TagColor.Green]: 'text-ca-green border-ca-green',
	[TagColor.Orange]: 'text-ca-orange border-ca-orange',
	[TagColor.Purple]: 'text-ca-purple border-ca-purple',
	[TagColor.Red]: 'text-ca-red border-ca-red',
	[TagColor.White]: 'text-ca-black border-ca-silver',
};

interface Props {
	label: string;
	removable?: boolean;
	color?: TagColor;
	onClick?: () => void;
}

const Tag: FunctionComponent<Props> = ({
	label,
	color = TagColor.White,
	removable,
	onClick,
}) => {
	if (!label) return null;

	return (
		<button
			tabIndex={onClick ? 0 : -1}
			className={clsx(
				'inline-flex justify-between items-center px-2.5 bg-white font-bold leading-none text-xxs border rounded-full transition-colors focus:outline-none',
				!onClick && 'cursor-text',
				!!onClick &&
					'cursor-pointer hover:border-ca-purple hover:bg-opacity-10 hover:bg-ca-purple focus:border-ca-purple focus:bg-opacity-10 focus:bg-ca-purple group',
				COLOR_CLASSES[color]
			)}
			style={{ paddingTop: '5px', paddingBottom: '5px' }}
			onClick={onClick}
		>
			<span>{label}</span>
			{removable && (
				<span>
					<CrossIcon className="ml-2.5 h-2 w-2 text-ca-gray" />
				</span>
			)}
		</button>
	);
};

export default Tag;
