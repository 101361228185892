import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { GET_DEFAULT_STRATEGY } from '../../../shared/api/strategies';

import get from '../../../shared/utils/get';
import pluralise from '../../../shared/utils/pluralise';
import useChannelQuery from '../../../shared/hooks/useChannelQuery';

import AlgorithmButton from '../../../shared/components/AlgorithmButton/AlgorithmButton';
import IconButton from '../../../shared/components/IconButton/IconButton';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import InfoIcon from '../../../shared/components/Icons/Info';
import CheckmarkIcon from '../../../shared/components/Icons/Checkmark';
import CrossIcon from '../../../shared/components/Icons/Cross';
import Table from '../../../shared/components/Table/Table';
import EditIcon from '../../../shared/components/Icons/Edit';
import LinearProgress from '../../../shared/components/Progress/LinearProgress';

const HEADINGS = [
	{ id: 'category.name', label: 'Category' },
	{ id: 'category.category_products_count', label: 'Products' },
	{ id: 'objective_residual_value', label: 'Optimization objective' },
	{ id: 'business_rules', label: 'Business rule(s)' },
	{ id: 'end_period', label: 'End of markdown period' },
	{ id: 'actions', label: '', align: 'right' },
];

const StrategyComponentsOverview = () => {
	const history = useHistory();

	const { isLoading, isFetching, data } = useChannelQuery(
		'default-strategy',
		GET_DEFAULT_STRATEGY
	);

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress visible={isFetching} />
			</div>
			<div className="py-6 space-y-6">
				<Table
					loading={isLoading}
					itemsLoading={5}
					headings={HEADINGS}
					rows={data?.components}
					renderCell={(row, columnId) => {
						if (columnId === 'actions') {
							return (
								<>
									<IconButton
										tooltip="Edit"
										icon={EditIcon}
										onClick={() =>
											history.push(
												`/strategy/${data?.id}/components/${row?.id}/edit`
											)
										}
									/>
								</>
							);
						}

						if (columnId === 'objective_residual_value') {
							return (
								<span className="flex flex-col space-y-2">
									<span className="flex items-center">
										<Tooltip
											content={
												row?.objective_residual_value > 0 &&
												row?.objective_residual_value < 100 ? (
													<>
														Value of rest stock at end of the season as a
														percentage of the cost price. <br /> A higher
														residual value will lead to less aggressive
														markdowns.
													</>
												) : null
											}
										>
											<span className="flex items-center space-x-1">
												{row?.objective_residual_value === 0 && (
													<span>Turnover</span>
												)}
												{row?.objective_residual_value === 100 && (
													<span>Margin</span>
												)}
												{row?.objective_residual_value > 0 &&
													row?.objective_residual_value < 100 && (
														<>
															<span>
																Custom {row?.objective_residual_value}%
															</span>
															<InfoIcon className="inline-block text-ca-gray h-3.5" />
														</>
													)}
											</span>
										</Tooltip>
									</span>
									<span className="flex items-center">
										Shipping cost{' '}
										{row?.objective_include_shipping_cost ? (
											<CheckmarkIcon className="inline ml-1 h-2 text-ca-green" />
										) : (
											<CrossIcon className="inline ml-1 h-2 text-ca-red" />
										)}
									</span>
									<span className="flex items-center">
										Return cost{' '}
										{row?.objective_include_return_cost ? (
											<CheckmarkIcon className="inline ml-1 h-2 text-ca-green" />
										) : (
											<CrossIcon className="inline ml-1 h-2 text-ca-red" />
										)}
									</span>
								</span>
							);
						}

						if (columnId === 'business_rules') {
							if (row?.[columnId]?.length > 2) {
								return (
									<span className="flex items-center">
										{row?.[columnId]?.[0]?.title}, {row?.[columnId]?.[1]?.title}
										&nbsp;+&nbsp;
										<Tooltip
											content={
												<>
													{row?.[columnId]?.slice(2).map((br) => (
														<Fragment key={br?.id}>
															{br?.title}
															<br />
														</Fragment>
													))}
												</>
											}
										>
											<span className="flex items-center space-x-1">
												<span>
													{pluralise(
														row?.[columnId]?.length - 2,
														'rule',
														'rules'
													)}
												</span>
												<InfoIcon className="inline-block text-ca-gray h-3.5" />
											</span>
										</Tooltip>
									</span>
								);
							}

							return row?.[columnId]?.length
								? row?.[columnId]?.map(({ title }) => title).join(', ')
								: 'None';
						}

						return get(row, columnId);
					}}
				/>
				{window._ENV_.REACT_APP_ALGORITHM_RUN_BUTTON_ENABLED && (
					<div className="flex">
						<AlgorithmButton name="active" strategyId="default" />
					</div>
				)}
			</div>
		</>
	);
};

export default StrategyComponentsOverview;
